<template>
  <div>
    <DialogShared v-model="dialog" scrollable :title="$t('log_view_full_log')">
      <LogViewerViewComponents :entity="logData" @onCancel="dialog = false" />
    </DialogShared>
    <v-row class="align-center">
      <v-col class="col-6">
        <v-text-field
          name="callID"
          v-model="callID"
          :label="$t('log_search_call_id')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          name="sipAddress"
          v-model="sipAddress"
          :label="$t('log_search_sip_address')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="col-1">
        <v-btn color="success" @click="filterLogs">{{ $t('filter') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="dateFrom"
              prepend-icon="mdi-calendar"
              readonly
              :label="$t('log_date_picker_from')"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(null)"
            >
              {{ $t('clear') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(dateFrom)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-2">
        <v-menu
          ref="dateMenuTo"
          v-model="dateMenuTo"
          :close-on-content-click="false"
          :return-value.sync="dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="dateTo"
              prepend-icon="mdi-calendar"
              readonly
              :label="$t('log_date_picker_to')"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDateTo(null)"
            >
              {{ $t('clear') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDateTo(dateTo)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-col>
        <v-text-field
          name="method"
          v-model="method"
          :label="$t('method')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="stage"
          v-model="stage"
          :label="$t('stage')"
          clearable
        ></v-text-field>
      </v-col> -->
      <v-col class="col-1">
        <v-text-field
          name="position"
          v-model="position"
          :label="$t('position')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          name="incident_id"
          v-model="incidentID"
          :label="$t('incident_id')"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="filteredLogItems"
          :items-per-page="100"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 200, 500, 1000, -1]
          }"
          :server-items-length="totalCount"
          @update:options="pagination"
          single-expand
          show-expand
          :expanded="expanded"
          item-key="id" selectable-key="id" single-select
          @item-expanded="expand"
        >
        <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <td class="text-start">
            <template v-if="item.count > 0">
              <v-btn
                icon
                @click="expand(!isExpanded)"
                class="v-data-table__expand-icon"
                :class="{'v-data-table__expand-icon--active' : isExpanded}">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </td>
        </template>
        <template v-slot:expanded-item>
          <td></td>
          <td colspan="7">
            <v-data-table :loading="innerLoading" :items="innerData" :headers="innerHeaders" :items-per-page="-1" :hide-default-footer="true">
              <template v-slot:[`item.direction`]="{ item }">
                <v-icon color="red" v-if="item.direction.toLowerCase() === 'incoming'">mdi-arrow-down-bold</v-icon>
                <v-icon color="green" v-if="item.direction.toLowerCase() === 'outgoing'">mdi-arrow-up-bold</v-icon>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn color="success" @click="() => handleClick(item)">{{ $t('log_view_full_log') }}</v-btn>
              </template>
            </v-data-table>
          </td>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import apiService from '@/modules/api/csp'
import DialogShared from '@/app/shared/dialog/DialogShared'
import LogViewerViewComponents from '@/app/widgets/log-viewer-widget/components/LogViewerViewComponents'

export default {
  name: 'LogosSettingsTab',
  components: { LogViewerViewComponents, DialogShared },
  data: (vm) => ({
    callID: '',
    dialog: false,
    expanded: [],
    position: '',
    totalCount: 0,
    incidentID: '',
    sipAddress: '',
    paginationData: {},
    filteredLogItems: [],

    logData: null,
    loading: false,

    dateTo: '',
    dateFrom: '',

    dateMenu: null,
    dateMenuTo: null,

    stage: '',
    method: '',

    // Inner table
    innerData: [],
    innerHeaders: [
      { text: vm.$t('call_id'), value: 'callID', sortable: false },
      { text: vm.$t('sip_address'), value: 'sipAddress', sortable: false },
      { text: vm.$t('position'), value: 'position', sortable: false },
      { text: vm.$t('incident_id'), value: 'incidentID', sortable: false },
      { text: vm.$t('method'), value: 'method', sortable: false },
      { text: vm.$t('direction'), value: 'direction', sortable: false },
      { text: vm.$t('response_time'), value: 'response_time', sortable: false },
      { text: vm.$t('stage'), value: 'stage', sortable: false },
      { text: vm.$t('date'), value: 'date', sortable: false, width: '30%' },
      { text: vm.$t('action'), value: 'action', sortable: false }
    ],
    innerLoading: false,
    // End of inner table

    headers: [
      { text: vm.$t('call_id'), value: 'callID', sortable: false },
      { text: vm.$t('sip_address'), value: 'sipAddress', sortable: false },
      { text: vm.$t('position'), value: 'position', sortable: false },
      { text: vm.$t('incident_id'), value: 'incidentID', sortable: false },
      { text: vm.$t('message_count'), value: 'count', sortable: false },
      { text: vm.$t('date'), value: 'date', sortable: false, width: '30%' }
    ]
  }),
  methods: {
    async expand (item) {
      this.innerData = []

      if (!item.value) return

      item = item.item

      const filters = this.getFilter()

      filters.call_id = (item.callID || '').trim()
      filters.position = (item.position || '').trim()
      filters.sip_address = (item.sipAddress || '').trim()

      this.innerLoading = true

      const response = await apiService.callLogDetails.search(filters)

      this.innerLoading = false

      if (response.status !== 200) return

      this.innerData = response.data.map(log => ({
        id: log.id,
        url: log.url,
        date: new Date(parseInt(log.write_time)),
        type: log.type,
        stage: log.stage,
        method: log.method,
        response_time: log.response_time,
        callID: log.call_id,
        message: log.sip_message,
        response: log.response,
        position: log.position,
        writeTime: log.write_time,
        createdAt: log.created_at,
        direction: log.direction,
        incidentID: log.incident_id,
        sipAddress: log.sip_address
      }))
    },
    async pagination (data) {
      this.paginationData = data

      this.getLogsFromDate()
    },
    handleClick (logData) {
      this.dialog = true
      this.logData = { ...logData }
    },
    async getLogsFromDate () {
      this.loading = true

      const filter = this.getFilter()

      const query = {
        ...filter,
        page: this.paginationData.page,
        itemsPerPage: this.paginationData.itemsPerPage
      }

      let logs = await apiService.callLogs.search(query)

      if (!logs.status === 200) return

      logs = logs.data

      this.totalCount = parseInt(logs.total)

      this.totalCount = isNaN(this.totalCount) ? -1 : this.totalCount

      logs = logs.data

      this.filteredLogItems = logs.map(log => ({
        id: log.id,
        date: log.created_at,
        count: log.total_logs,
        callID: log.call_id,
        position: log.position,
        writeTime: log.write_time,
        sipAddress: log.sip_address,
        incidentID: log.incidentID
      }))

      this.loading = false
    },
    saveDate (date) {
      this.$refs.dateMenu.save(date)
    },
    saveDateTo (date) {
      this.$refs.dateMenuTo.save(date)
    },
    getFilter () {
      return {
        stage: (this.stage || '').trim(),
        method: (this.method || '').trim(),
        call_id: (this.callID || '').trim(),
        date_to: this.dateTo ? new Date(this.dateTo).getTime() : '',
        date_from: this.dateFrom ? new Date(this.dateFrom).getTime() : '',
        position: (this.position || '').trim(),
        sip_address: (this.sipAddress || '').trim(),
        incident_id: (this.incidentID || '').trim()
      }
    },
    filterLogs () {
      this.paginationData.page = 1

      this.getLogsFromDate()
    }
  }
}
</script>
