<template>
  <div>

    <DialogShared v-model="dialog" max-width="1200" scrollable :title="dialogTitle">
      <LanguageForm :key="formKey" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <div class="d-flex align-center mb-2">

      <h2 >
        {{ $t('language_settings')}}
      </h2>

      <v-spacer />

      <v-btn v-if="$can('can_create_languages')" class="ml-auto" @click="onCreate" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add_new')}}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table class="transparent-background"  :dark="$vuetify.theme.dark"
        :items="items"
        :loading="loading"
        :headers="headers"
    >

      <template v-slot:[`item.actions`]="{ item }">

        <v-btn v-if="$can('can_update_languages')" class="mr-1" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span> {{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$can('can_delete_languages')" class="mr-1" color="error" small @click.stop="onDelete(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-delete-outline
              </v-icon>
            </template>
            <span> {{ $t('delete')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import LanguageForm from '@/app/widgets/language-widget/components/LanguageFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {

  name: 'LanguageWidget',

  components: { DialogShared, LanguageForm },
  data: (vm) => ({
    entity: null,
    formKey: 0,
    dialogTitle: vm.$t('create_language'),
    dialog: false,
    items: [],
    loading: false,
    headers: [

      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('code'), value: 'code', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  watch: {

  },

  mounted () {
    this.load()
    this.initModule()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    initModule () {
      apiService.languages.list().then((response) => {

      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    refreshForm () {
      this.formKey += 1
    },

    load () {
      this.loading = true

      apiService.languages.list().then((result) => {
        this.items = result.data

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.load()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update_language')

      apiService.languages.get(item.id).then((response) => {
        this.refreshForm()
        this.entity = response.data

        this.dialog = true
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    onCreate () {
      this.dialogTitle = this.$t('create_language')
      this.entity = null
      this.refreshForm()
      this.dialog = true
    },

    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('language') })).then(confirmed => {
        if (confirmed) {
          apiService.languages.delete(item.id).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('language_deleted')
            })

            this.load()
          }).catch((error) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message[0]
            })
          })
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
