var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"900","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('SpeedDialFormComponent',{key:_vm.formKey,attrs:{"items":_vm.items,"parentId":_vm.parentId,"realmName":_vm.realmName,"entity":_vm.entity},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('speed_dial'))+" "+_vm._s(_vm.realmName)+" ")]),_c('v-spacer'),_c('v-select',{staticClass:"mt-2",staticStyle:{"max-width":"300px"},attrs:{"label":_vm.$t('realm'),"items":_vm.realms},on:{"change":function($event){return _vm.loadTree()}},model:{value:(_vm.realmName),callback:function ($$v) {_vm.realmName=$$v},expression:"realmName"}}),(_vm.realmName)?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.addToRootClick}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,false,1287216139)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add_to_root')))])])],1)],1):_vm._e()],1),(_vm.realmName)?_c('div',[(_vm.$canAny('can_delete_speed_dial'))?_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-1 ",attrs:{"disabled":_vm.loading,"color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteAll()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$t('delete_all_speed_dials'))+" ")]}}],null,false,3928890474)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete_all_speed_dials')))])])],1)],1):_vm._e(),_c('draggable-treeview',{on:{"nodeChange":_vm.saveOrder,"updateTree":_vm.saveOrder},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",style:('color: '+ item.data.color)},[_vm._v(_vm._s(item.data.icon))])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex d-inline-flex align-center"},[_c('span',{staticClass:"primary--text mr-4 ml-4"},[_vm._v(" "+_vm._s(item.data.name)+" ")]),_c('span',{staticClass:"mr-4"},[_vm._v(" ("+_vm._s(item.data.hot_contact_permission)+") ("+_vm._s(item.data.order_by)+") ")]),_c('v-avatar',{attrs:{"size":"25","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item.data)}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-pencil ")])],1),(_vm.$canAny('can_delete_speed_dial'))?_c('v-avatar',{staticClass:"ml-2",attrs:{"size":"25","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteClick(item)}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-delete ")])],1):_vm._e(),(item.data.is_folder)?_c('v-avatar',{staticClass:"ml-2",attrs:{"size":"25","color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.onCreate(item.data)}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()],1)]}}],null,false,2253389934),model:{value:(_vm.itemsTree),callback:function ($$v) {_vm.itemsTree=$$v},expression:"itemsTree"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }