<template>

  <validation-observer ref="observer" >

    <v-form class="pt-4" @submit.prevent="onSubmit" novalidate >

      <v-row dense>
        <v-col>
            <validation-provider v-slot="{ errors }" name="name" rules="required">
             <v-text-field outlined name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" ></v-text-field>
           </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" name="code" rules="required">
            <v-text-field outlined name="code" :label="$t('code')" v-model="form.code" :error-messages="errors" ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="loading" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

  </validation-observer>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'LanguageFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    loading: false,
    form: {
      id: null,
      name: '',
      code: ''
    }
  }),

  computed: {

  },

  mounted () {
    Object.assign(this.form, this.entity)
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.loading = true

        if (this.form.id) {
          apiService.languages.edit(this.form.id, this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('language_updated')
            })

            this.loading = false

            this.$emit('onSubmit')
          }).catch((error) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message[0]
            })

            this.loading = false
          })
        } else {
          apiService.languages.create(this.form).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('language_created')
            })
            this.loading = false
            this.$emit('onSubmit')
          }).catch((error) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message[0]
            })
            this.loading = false
          })
        }
      }
    },
    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
