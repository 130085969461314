<template>
  <div>

    <div class="d-flex align-center mb-2">

      <h2 >
        {{ $t('translations_settings')}}
      </h2>

    </div>

    <v-container>
      <v-row class="d-flex justify-center">
        <v-col class="d-flex align-center justify-center">
          <span>{{ $t('choose_translations') }}</span>
        </v-col>
        <v-col>
          <v-select :disabled="loading" v-model="form.application_id" :dark="$vuetify.theme.dark" :items="apps"
                    :label="$t('application')" item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col>
          <v-select @input="onLanguageSwitch" :disabled="loading" v-model="form.language_id" :dark="$vuetify.theme.dark"
                    :items="languages" :label="$t('language')" item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col v-if="form.language_id">
          <v-select :disabled="loading" clearable v-model="form.realm" :dark="$vuetify.theme.dark" :items="realms"
                    :label="$t('realm')"></v-select>
        </v-col>
        <v-col class="pt-5">
          <v-btn :disabled="loading" @click="loadTranslations" color="green">{{ $t('load') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="translations.length > 0 ">
      <v-row>
        <v-col>

          <v-data-table :search="search"
                        fixed-header height="500"
                        class="transparent-background"
                        :dark="$vuetify.theme.dark"
                        :items="translations"
                        :loading="loading"
                        :headers="headers"
          >

            <template v-slot:top>

              <div class="d-flex">
                <v-text-field v-model="search" :label="$t('search')" class="mx-4"></v-text-field>
              </div>

            </template>

            <template v-slot:[`item.translation_value`]="{ item }">
              <div class="pb-1 pt-1">
                <v-text-field

                  tabindex="1" hide-details v-model="item.translation_value" required outlined
                  @keyup.enter="saveTranslation(item)">

                  <template v-if="item.language_id > 1 && item.id" v-slot:append>
                    <v-icon left>
                      mdi-check
                    </v-icon>
                  </template>

                </v-text-field>

                <div class="mt-1" v-if="item.en_translation_value">
                  {{ $t('english') }}: {{ item.en_translation_value }}
                </div>

              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">

              <v-btn v-if="$canAny(['can_manage_chs_translations','can_manage_cad_translations'])" tabindex="0" color="green" small @click.stop="saveTranslation(item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-content-save
                    </v-icon>
                  </template>
                  <span> {{ $t('save') }}</span>
                </v-tooltip>
              </v-btn>

            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

import { mapActions } from 'vuex'
import apiService from '@/modules/api/csp'

export default {

  name: 'TranslationsWidget',

  components: {},
  data: (vm) => ({

    form: {
      application_id: null,
      language_id: null,
      realm: null
    },

    search: null,

    valid: false,

    headers: [
      { text: vm.$t('translation_key'), value: 'translation_key', sortable: false, width: 100 },
      { text: vm.$t('translation_value'), value: 'translation_value', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ],

    apps: [],
    languages: [],
    realms: [],
    translations: [],

    entity: null,
    formKey: 0,
    items: [],
    loading: false
  }),

  watch: {},

  mounted () {
    this.loadApps()
    this.loadLanguages()
    this.loadRealms()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async loadApps () {
      this.loading = true
      await apiService.apps.list().then((response) => {
        this.apps = response.data

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async loadRealms () {
      this.loading = true
      await apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async loadLanguages () {
      this.loading = true
      await apiService.languages.list().then((response) => {
        this.languages = response.data
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async loadTranslations () {
      if (!this.form.application_id || !this.form.language_id) {
        return false
      }

      this.loading = true
      await apiService.translations.list(this.form).then((response) => {
        this.translations = response.data
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onLanguageSwitch (input) {
      this.form.realm = null
      this.translations = []
    },

    async saveTranslation (item) {
      if (!this.$canAny(['can_manage_chs_translations', 'can_manage_cad_translations'])) {
        return false
      }

      if (!this.form.application_id || !this.form.language_id) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('missing_language_or_application')
        })
        return false
      }

      this.loading = true

      item.language_id = this.form.language_id
      item.app_id = this.form.application_id
      if (this.form.realm) {
        item.realm = this.form.realm
      }

      if (item.id) {
        await apiService.translations.edit(item.id, item.app_id, item, this.form.realm).then((response) => {
          item.saved = true
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: this.$t('something_went_wrong_please_try_again_later')
          })
        })
      } else {
        await apiService.translations.create(item, this.form.realm).then((response) => {
          item.id = response.data.id
          item.saved = true

          this.loading = false
        }).catch(() => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: this.$t('something_went_wrong_please_try_again_later')
          })
        })
      }
    }

  }

}
</script>

<style scoped>

</style>
