export default function redisSettingsApiModule (instance) {
  return {
    getInfo: async () => {
      const resource = '/redis/info'

      const response = await instance.get(resource)

      return response
    },
    clearCache: async () => {
      const resource = '/redis/flushall'

      const response = await instance.delete(resource)

      return response
    }
  }
}
