export default function languageApiModule (instance) {
  return {

    /**
     * Return list of languages
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    list: async (data) => {
      const resource = `/languages?time=${Date.now()}`

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Get single language
     * @param id
     * @returns {Promise<*>}
     */
    get: async (id) => {
      const resource = '/languages/' + id

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create Language
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: async (data) => {
      const resource = '/languages'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Update language
     *
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: async (id, data) => {
      const resource = `/languages/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete Language
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: async (id) => {
      const resource = `/languages/${id}`

      const data = {}

      const response = await instance.delete(resource, { params: data })

      return response
    }

  }
}
