<template>

  <v-card tile flat color="secondary_card">
    <v-card-text>
      <validation-observer ref="observer">

        <validation-observer ref="observer">

          <v-card tile flat color="secondary_card">
            <v-card-text>

              <v-form ref="form" :disabled="loading" @submit.prevent="onSubmit" novalidate>

                <v-row class="pb-4">
                  <v-col cols="12" class="text-right pt-2">
                    <v-btn @click="addRow" :rounded="true" small color="success" cypress="add_new_number">
                      {{ $t('add_new_number' )}}
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <validation-provider v-slot="{ errors }" :name="$t('contact_permission')" rules="required">
                  <v-select v-if="realm" cypress="contact_permission" :error-messages="errors" v-model="form.contact_permission" :items="[ {
        value: 'private',
        label: $t('private')
      },
      {
        value: 'realm',
        label: $t('realm') + ' ' + realm
      }]"
                            outlined name="contact_permission" item-text="label" item-value="value"
                            :label="$t('contact_permission')"></v-select>
                </validation-provider>

                <validation-provider v-slot="{ errors }" :name="$t('contact_groups')" rules="">
                  <v-select :dark="$vuetify.theme.dark" :error-messages="errors" cypress="contact_groups"
                            v-model="form.contact_book_group_ids" :items="contactGroups || []"
                            outlined multiple small-chips name="contact_groups" item-text="name" item-value="id"
                            :label="$t('contact_groups')"></v-select>
                </validation-provider>

                <v-radio-group v-model="form.is_primary">
                  <template v-for="(item, index) in form.contact_book_phones">
                    <v-sheet color="transparent" elevation="3" class="pa-4 mt-2" :rounded="true" :key="index">
                      <v-row>
                        <v-col cols="8" class="pb-0">
                          <v-radio :key="index" :value="index" :label="$t('make_primary')" class="my-0"
                                   @click="changePrimary()" :cypress="`contact_book_primary_${index}`"></v-radio>
                        </v-col>
                        <v-col cols="4" class="text-right pb-0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn @click="removeRow(index)" icon v-on="on">
                                <v-icon color="error">
                                  mdi-minus
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('remove_phone_number')}}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <validation-provider v-slot="{ errors }" :vid="`contact_phone_number_${index}`"
                                               :name="$t('phone_number')" rules="required">

                            <v-text-field :error-messages="errors" :key="`contact_phone_number_${index}`"
                                          v-model="item.phone" name="phone_number" :label="$t('phone_number')" required outlined
                                          :cypress="`contact_phone_number_${index}`"></v-text-field>

                          </validation-provider>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <validation-provider v-slot="{ errors }" :vid="`contact_name_${index}`" :name="$t('name')"
                                               rules="required">

                            <v-text-field :error-messages="errors" :key="`contact_name_${index}`"
                                          v-model="item.name" name="name" :label="$t('name')" required outlined
                                          :cypress="`contact_name_${index}`"></v-text-field>

                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </template>
                </v-radio-group>

              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn class="mr-5" color="passive_button" :disabled="loading" @click="onCancel()">
                {{ $t('cancel')}}
              </v-btn>
              <v-btn @click="onSubmit" :disabled="loading" color="error" cypress="submit_contact">
                {{ $t('save')}}
              </v-btn>
            </v-card-actions>
          </v-card>

        </validation-observer>

      </validation-observer>

    </v-card-text>
  </v-card>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'ProxyFormComponent',

  props: ['entity', 'realm'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    search: '',
    loading: false,

    contactGroups: [],

    form: {
      id: null,
      contact_permission: 'private',
      contact_book_group_ids: [],
      phone: '',
      name: '',
      note: '',
      icon: '',
      tandem_transfer: '',
      category: '',
      '911_text_transfer': '',
      quick_dial_harris: '',
      realm: null
    }
  }),

  computed: {},

  async mounted () {
    await this.loadContactGroups()

    if (this.entity) {
      this.form = JSON.parse(JSON.stringify(this.entity))
      this.form.is_primary = this.form.contact_book_phones.findIndex(phones => phones.is_primary === 1)
      this.form.contact_book_group_ids = this.form.contact_book_group_ids.map(i => parseInt(i))
      this.form.contact_book_phones = this.form.contact_book_phones.map(i => {
        i.id = parseInt(i.id)
        i.contact_book_id = parseInt(i.contact_book_id)

        return i
      })
    } else {
      this.form = {
        id: null,
        contact_permission: 'private',
        contact_book_group_ids: [],
        phone: '0',
        name: '',
        note: '',
        icon: '',
        tandem_transfer: '',
        category: '',
        '911_text_transfer': '',
        quick_dial_harris: '',
        is_primary: 0,
        contact_book_phones: [{
          phone: '',
          name: '',
          is_primary: 1
        }],
        realm: null
      }
    }
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async loadContactGroups () {
      this.loading = true

      await apiService.contactGroups.get().then((result) => {
        this.contactGroups = result.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    addRow () {
      this.form.contact_book_phones.push({
        phone: '',
        name: '',
        is_primary: 0
      })
    },
    removeRow (index) {
      if (this.form.contact_book_phones.length > 1) {
        if (this.form.is_primary === index) {
          this.form.contact_book_phones.splice(index, 1)
          this.form.contact_book_phones[0].is_primary = 1
          this.form.is_primary = 0
        } else {
          this.form.contact_book_phones.splice(index, 1)
          this.form.is_primary = this.form.contact_book_phones.findIndex(phones => phones.is_primary === 1)
        }
      }
    },
    changePrimary () {
      this.form.contact_book_phones.forEach(item => { item.is_primary = 0 })
      this.form.contact_book_phones[this.form.is_primary].is_primary = 1
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.loading = true

      if (this.form.contact_permission === 'realm') {
        this.form.realm = this.realm
      }

      if (this.form.id) {
        apiService.contactBook.edit(this.entity.id, this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('updated')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      } else {
        apiService.contactBook.create(this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('created')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
