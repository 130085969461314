export default function callLinesApiModule (instance) {
  return {

    /**
     * Return list of realm settings
     * @param realmName
     * @returns {Promise<*>}
     */
    load: async (realmName = null) => {
      let resource = '/custom-call-lines'

      if (realmName) {
        resource = `/custom-call-lines?realm_name=${realmName}`
      }

      const response = await instance.get(resource)

      return response
    },

    /**
     * Update Realm Setting
     * @param id
     * @param data
     * @param realmName
     * @returns {Promise<*>}
     */
    edit: async (id, data, realmName = null) => {
      let resource = `/custom-call-lines/${id}`

      if (realmName) {
        resource = `/custom-call-lines/${id}?realm_name=${realmName}`
      }

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Update Realm Setting
     * @param data
     * @param realmName
     * @returns {Promise<*>}
     */
    add: async (data, realmName = null) => {
      let resource = '/custom-call-lines'

      if (realmName) {
        resource = `/custom-call-lines/?realm_name=${realmName}`
      }

      const response = await instance.post(resource, data)

      return response
    },

    delete: async (id) => {
      const resource = `/custom-call-lines/${id}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
