var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"name","label":_vm.$t('name'),"error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1)],1),_c('v-col'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"layer_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"layer_id","label":_vm.$t('layer_id'),"error-messages":errors},model:{value:(_vm.form.layer_id),callback:function ($$v) {_vm.$set(_vm.form, "layer_id", $$v)},expression:"form.layer_id"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"client_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"client_id","label":_vm.$t('client_id'),"error-messages":errors},model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":"client_secret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"client_secret","label":_vm.$t('client_secret'),"error-messages":errors},model:{value:(_vm.form.client_secret),callback:function ($$v) {_vm.$set(_vm.form, "client_secret", $$v)},expression:"form.client_secret"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-switch',{attrs:{"label":_vm.$t('enable_layer'),"persistent-hint":""},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"passive_button","disabled":_vm.loading},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"type":"submit","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }