export default function contactBookApiModule (instance) {
  return {

    /**
     * Get contacts from contact book
     * @param data
     * @returns {Promise<*>}
     */
    get: async (data) => {
      const resource = `/contact-books?timestamp=${new Date().getTime()}`

      const response = await instance.get(resource, {
        params: data
      })

      return response
    },

    /**
     * Search contact book
     * @param data
     * @returns {Promise<*>}
     */
    search: async (data) => {
      const resource = `/contact-books/search/${data.search}`

      const response = await instance.get(resource, {
        params: data
      })

      return response
    },

    /**
     * Create contact book by file
     * @param data
     * @returns {Promise<*>}
     */
    createByFile: async (data) => {
      const resource = '/contact-books/create-by-file'

      const formData = new FormData()
      formData.append('file', data.file)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response
    },

    /**
     * Create contact book entry
     * @param data
     * @returns {Promise<*>}
     */
    create: async (data) => {
      const resource = '/contact-books'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Edit contact book entry
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const resource = `/contact-books/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete contact book entry
     * @param id
     * @returns {Promise<*>}
     */
    delete: async (id) => {
      const resource = `/contact-books/${id}`

      const response = await instance.delete(resource)

      return response
    },

    /**
     * Delete all contacts
     * @param realmName
     * @returns {Promise<*>}
     */
    deleteAll: async (realmName) => {
      const resource = '/contact-books/all'

      const response = await instance.delete(resource, {
        params: {
          realm_name: realmName
        }
      })

      return response
    },

    /**
     * Delete selected contacts
     * @param contactIds
     * @param realmName
     * @returns {Promise<*>}
     */
    deleteSelected: async (contactIds, realmName) => {
      const resource = '/contact-books'

      const response = await instance.delete(resource, {
        data: {
          contact_book_ids: contactIds
        },
        params: {
          realm_name: realmName
        }
      })

      return response
    },

    /**
     * Download contact books
     * @param realm
     * @returns {Promise<*>}
     */
    download: async (realm) => {
      const resource = `/contact-books/export?realm_name=${realm}`

      return await instance.get(resource, {
        responseType: 'arraybuffer'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel' }))
        const file = document.createElement('a')
        file.href = url
        file.setAttribute('download', `${realm}.xlsx`)
        document.body.appendChild(file)
        file.click()
      })
    }

  }
}
