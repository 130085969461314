import Vue from 'vue'
import Vuex from 'vuex'

import global from '@/store/global'
import settings from '@/store/settings'
import layout from '@/store/layout'
import notifications from '@/app/shared/notification/store'
import roles from '@/app/widgets/roles-widget/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    notifications,
    settings,
    layout,
    roles
  },
  strict: process.env.NODE_ENV !== 'production'
})
