<template>

  <validation-observer ref="observer" >

    <v-form class="pt-4" @submit.prevent="onSubmit" autocomplete="off" novalidate :disabled="loading" >

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="name" :name="$t('name')" rules="required">
            <v-text-field outlined  name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col>
          <validation-provider v-slot="{ errors }" vid="password" :name="$t('password')" :rules="{ required : !form.id}">
            <v-text-field type="password" outlined  name="password" autocomplete="new-password" :label="$t('password')" v-model="form.password" :error-messages="errors" ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense >
        <v-col>
          <validation-provider v-slot="{ errors }" vid="email" :name="$t('email')" rules="email|required">
            <v-text-field outlined  name="email" autocomplete="new-email" :label="$t('email')" v-model="form.email" :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="roles" :name="$t('roles')" rules="required">

            <v-autocomplete outlined onabort="roles"
                            v-model="form.roles"
                            :items="roles"
                            :label="$t('roles')" item-value="name" item-text="name"
                            multiple small-chips
                            persistent-hint :error-messages="errors"
            ></v-autocomplete>

          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="direct_permissions" :name="$t('permissions')" rules="">

            <v-autocomplete  clearable outlined  onabort="permissions"
                            v-model="form.direct_permissions"
                            :items="permissions"
                            :label="$t('permissions')" item-value="name" item-text="name"
                            multiple small-chips
                            persistent-hint :error-messages="errors"

            ></v-autocomplete>

          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="mobile_phone" :name="$t('mobile_phone')">
            <v-text-field outlined  name="mobile_phone" :label="$t('mobile_phone')" v-model="form.mobile_phone" :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="loading" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-form>

  </validation-observer>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'UserFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    loading: false,
    form: {
      id: null,
      name: '',
      email: '',
      password: null,
      mobile_phone: null,
      roles: [],
      direct_permissions: [],
      expire_after: '',
      pass_upper: false,
      pass_lower: false,
      pass_number: false
    }
  }),

  computed: {
    ...mapState({
      roles: state => state.roles.roles,
      permissions: state => state.roles.permissions
    })

  },

  mounted () {
    Object.assign(this.form, this.entity)
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        // when there is no new password set delete password key.
        if (this.form.password == null) {
          delete this.form.password
        }

        this.loading = true
        this.$emit('saving')

        if (this.form.id) {
          apiService.users.edit(this.form.id, this.form).then((response) => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('user_updated')
            })

            this.$emit('onSubmit')
          }).catch((error) => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message[0]
            })
          })
        } else {
          apiService.users.create(this.form).then((response) => {
            this.loading = false
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('user_created')
            })

            this.$emit('onSubmit')
          }).catch((error) => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message[0]
            })
          })
        }
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
