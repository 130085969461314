<template>
  <div>
    <v-row>
      <v-col class="col-1">
        <v-btn color="success" @click="refresh">Refresh</v-btn>
      </v-col>
      <v-col class="col-1">
        <v-btn color="success" @click="clearCache">Clear cache</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :items-per-page="100"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 200, 500, 1000, -1]
          }"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import apiService from '@/modules/api/csp'

export default {
  name: 'RedisSettingsWidget',
  data: (vm) => ({
    items: [],
    loading: false,

    headers: [
      { text: vm.$t('redis_name'), value: 'name', sortable: false },
      { text: vm.$t('redis_value'), value: 'value', sortable: false },
      { text: vm.$t('redis_percent'), value: 'percent', sortable: false }
    ]
  }),
  methods: {
    async getLogsFromDate () {
      this.loading = true

      const response = await apiService.redisSettings.getInfo()

      if (response.status !== 200) return

      const data = response.data

      this.items = this.parseInfo(data)

      this.loading = false
    },
    parseInfo (data) {
      const memory = [
        // Memory
        'maxmemory',
        'used_memory',
        'total_system_memory'
      ]

      let items = []
      let maxmemory = 0

      data.forEach(item => {
        let name = item.name
        let value = item.value

        if (name === 'maxmemory') {
          maxmemory = parseInt(value)

          if (isNaN(maxmemory)) maxmemory = 0
        }

        if (memory.indexOf(name) >= 0) {
          value = (parseInt(value) / 1024 / 1024).toFixed(2) + ' MB'
        }

        if (name === 'db0') {
          value = value.split(',')

          value.forEach(v => {
            v = v.split('=')

            items.push({
              name: this.getName(v[0]),
              value: v[1],
              orignName: item.name,
              originValue: item.value
            })
          })

          return
        }

        if (name === 'used_cpu_sys') value += ' %'

        name = this.getName(name)

        items.push({
          name,
          value,
          orignName: item.name,
          originValue: item.value
        })
      })

      items = items.map(item => {
        if (item.orignName !== 'used_memory') return item

        item.percent = (maxmemory === 0 ? 0 : item.originValue / maxmemory).toFixed(2) + ' %'

        return item
      })

      return items
    },
    getName (name) {
      name = name.charAt(0).toUpperCase() + name.slice(1)

      name = name.split('_').join(' ')

      return name
    },
    refresh () {
      this.getLogsFromDate()
    },
    async clearCache () {
      await apiService.redisSettings.clearCache()

      this.getLogsFromDate()
    }
  },
  mounted () {
    this.getLogsFromDate()
  }
}
</script>
