<script>
import apiService from '@/modules/api/csp'
import { mapActions } from 'vuex'
import DialogShared from '@/app/shared/dialog/DialogShared'
import MapLayersSettingsForm from './components/MapLayersSettingsForm'

import { minutesPrefixPluralForm } from './helpers/minutesPrefixPluralForm'
import { truncateURL } from './helpers/truncateURL'

export default {
  name: 'MapLayersSettingsTab',
  components: { MapLayersSettingsForm, DialogShared },
  data: (vm) => ({
    loading: false,
    formKey: 0,
    realms: [],
    mapLayersList: [],
    realmName: '',

    dialogTitle: '',
    dialog: false,
    isEditModalMode: null,
    entity: {},

    headers: [
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('layer_id'), value: 'layer_id', sortable: false },
      { text: vm.$t('client_id'), value: 'client_id', sortable: false },
      { text: vm.$t('client_secret'), value: 'client_secret', sortable: false },
      { text: vm.$t('enable_layer'), value: 'is_active', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]
  }),
  async created () {
    await this.loadRealms()

    if (this.realms.length > 0) {
      this.realmName = this.realms[0]
    }

    this.fetchMapLayersHandler()
  },
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    minutesPrefixPluralForm,
    truncateURL,
    async loadRealms () {
      this.loading = true
      try {
        const realmsList = await apiService.sip.getRealms()
        this.realms = realmsList.filter(n => n != null)
      } catch (error) {
        console.error('fetch error!', error)
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      } finally {
        this.loading = false
      }
    },
    async fetchMapLayersHandler () {
      this.loading = true
      try {
        const mapLayersListResult = await apiService.mapLayersSettings.get({ realm_name: this.realmName })
        this.mapLayersList = mapLayersListResult.data
      } catch (error) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      } finally {
        this.loading = false
      }
    },
    async loadList () {
    },
    addToCurrentList (newMapLayer) {
      this.mapLayersList = [...this.mapLayersList, newMapLayer]
      this.dialog = false
    },
    updateInCurrentList (updatedMapLayer) {
      this.mapLayersList = this.mapLayersList.map(mapLayer =>
        mapLayer.id === updatedMapLayer.id ? updatedMapLayer : mapLayer)
      this.dialog = false
    },
    onCreateModalOpen () {
      this.dialogTitle = this.$t('add')
      this.entity = {
        realm: this.realmName
      }
      this.isEditModalMode = false
      this.refreshForm()
      this.dialog = true
    },
    onEditModalOpen (item) {
      this.dialogTitle = this.$t('update')
      this.entity = { ...item, realm: this.realmName }
      this.refreshForm()
      this.isEditModalMode = true
      this.dialog = true
    },
    async onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('chat_text_line') }))
        .then(async confirmed => {
          if (confirmed) {
            this.loading = true

            try {
              await apiService.mapLayersSettings.delete(item.id)
              this.mapLayersList = this.mapLayersList.filter(mapLayer => mapLayer.id !== item.id)
              this.notificationSnackBarAction({
                show: true,
                color: 'success',
                text: this.$t('updated')
              })
            } catch (error) {
              this.notificationSnackBarAction({
                show: true,
                color: 'error',
                text: this.$t('something_went_wrong_please_try_again_later')
              })
            } finally {
              this.loading = false
            }
          }
        })
    },
    async onLayerToggle (newMapLayer) {
      try {
        const response = await apiService.mapLayersSettings.edit({ id: newMapLayer.id }, { is_active: newMapLayer.is_active })
        this.updateInCurrentList(response.data)
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('updated')
        })
      } catch (error) {
        console.error(error)
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      } finally {
        this.loading = false
      }
    },
    onFormSubmit () {
      this.dialog = false
    },
    refreshForm () {
      this.formKey++
    }
  }
}
</script>

<template>
  <div>
    <DialogShared v-model='dialog' max-width='900' scrollable :title='dialogTitle'>
      <MapLayersSettingsForm :key='formKey' :isEditModalMode="isEditModalMode" :entity='entity'
        @onCancel='dialog = false' @onSubmit='onFormSubmit' @addToCurrentList='addToCurrentList'
        @updateInCurrentList="updateInCurrentList" />
    </DialogShared>

    <div class='d-flex align-center mb-2'>
      <h2>{{ $t('map_layers_settings') }}</h2>
      <v-spacer />

      <v-select style='max-width: 300px' class='mt-2' :items='realms' :label="$t('realm')" v-model='realmName'
        @change='fetchMapLayersHandler' />
    </div>

    <div class='text-right'>
      <v-btn @click='onCreateModalOpen' icon>
        <v-tooltip bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-icon color='error' v-bind='attrs' v-on='on'>
              mdi-plus
            </v-icon>
          </template>
          <span>{{ $t('add') }}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-alert type='info' color='red lighten-2' dark>
      {{ $t('changes_for_account') }} <b>{{ realmName }}</b>
    </v-alert>

    <v-data-table :disable-pagination='true' :hide-default-footer='true' class='transparent-background'
      :items='mapLayersList' :loading='loading' :headers='headers'>
      <template v-slot:[`item.url`]='{ item }'>
        <v-tooltip bottom class="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ truncateURL(item.url) }}
            </span>
          </template>
          <span>{{ item.url }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.refresh_rate`]='{ item }'>
        <span>
          {{ `${item.refresh_rate} ${minutesPrefixPluralForm(item.refresh_rate)}` }}
        </span>
      </template>

      <template v-slot:[`item.is_active`]='{ item }'>
        <v-switch :disabled='loading' v-model='item.is_active' @change='onLayerToggle(item)' />
      </template>
      <template v-slot:[`item.actions`]='{ item }'>
        <v-btn :disabled='loading' class='mr-2' color='green' small @click.stop='onEditModalOpen(item)'>
          <v-tooltip bottom>
            <template v-slot:activator='{ on, attrs }'>
              <v-icon small v-bind='attrs' v-on='on'>
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
        </v-btn>
        <v-btn :disabled='loading' class='mr-1' color='red' small @click.stop='onDelete(item)'>
          <v-tooltip bottom>
            <template v-slot:activator='{ on, attrs }'>
              <v-icon small v-bind='attrs' v-on='on'>
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<style>
.tooltip-content {
  white-space: normal;
  word-wrap: break-word;
}
</style>
