export default function translationsApiModule (instance) {
  return {

    /**
     * Return list of translations by application
     * @param params - {application_id, translation_id, realm}
     * @returns {Promise<*>}
     */
    list: async (params) => {
      const resource = `/translations/apps/${params.application_id}/languages/${params.language_id}?${new Date().getTime()}`

      const response = await instance.get(resource, {
        params: {
          realm_name: params.realm
        }
      })

      return response
    },

    /**
     * Create translation entry
     * @param data - { app_id, language_id, realm, translation_key, translation_value}
     * @param realm
     * @returns {Promise<*>}
     */
    create: async (data, realm = null) => {
      const resource = '/translations'

      const response = await instance.post(resource, data, {
        params: {
          realm_name: realm
        }
      })

      return response
    },

    /**
     * Update translation
     * @param translationId
     * @param appId
     * @param data
     * @param realm
     * @returns {Promise<*>}
     */
    edit: async (translationId, appId, data, realm = null) => {
      const resource = `/translations/${translationId}/apps/${appId}`

      const response = await instance.patch(resource, data, {
        params: {
          realm_name: realm
        }
      })

      return response
    }

  }
}
