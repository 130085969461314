
export const sipAddressesMutator = async (state, payload) => {
  state.addresses = payload
}

export const sipDefaultAddressMutator = async (state, payload) => {
  state.sipDefaultAddress = payload
}

export const egressAuthMutator = async (state, authUser) => {
  state.egressAuthState = authUser
}

export const setAccountsMutator = async (state, payload) => {
  state.accounts = payload
}

export const setAccountNamesMutator = async (state, payload) => {
  state.accountNames = payload.map(account => account.name)
}

export const userSettingsMutator = async (state, payload) => {
  state.userSettings = payload
}

export const globalSettingsMutator = async (state, payload) => {
  state.globalSettings = payload
}
