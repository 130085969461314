<template>

  <div>

    <DialogShared max-width="1000" v-model="contactBookDialog" :title="$t('bind_contact')">
      <v-card tile flat color="secondary_card">
        <v-card-text>

          <ContactBookComponent :realmName="realmName" @bindContact="bindContact"></ContactBookComponent>

        </v-card-text>
      </v-card>
    </DialogShared>

    <v-card tile flat color="secondary_card">
      <v-card-text>
        <validation-observer ref="observer">

          <v-form @submit.prevent="onSubmit" novalidate>

            <v-row dense>
              <v-col cols="4">
                <v-switch inset v-model="form.is_folder" :label="$t('folder')" cypress="switch_folder_contact"></v-switch>
              </v-col>
              <v-col cols="8">
                <validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
                  <v-text-field :label="$t('name')" name="name" outlined :disabled="loading" :error-messages="errors" cypress="speed_dial_name"
                                v-model="form.name"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col></v-col>

            </v-row>

            <v-row>
              <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('parent_folder')" rules="">
                  <v-autocomplete clearable :label="$t('parent_folder')" autocomplete="false" item-text="name" item-value="id" cypress="speed_dial_parent_id"
                                  :disabled="loading" :error-messages="errors" v-model="form.parent_id" :items="getItems" required
                                  outlined>
                    <template v-slot:item="data">
                      {{ data.item.name }}
                    </template>

                    <template v-slot:selection="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">

                <validation-provider v-slot="{ errors }" :name="$t('contact_permission')" rules="required">
                  <v-select v-if="realmName"
                            :error-messages="errors"
                            v-model="form.hot_contact_permission"
                            cypress="speed_dial_permissions"
                            :items="[ {
                            value: 'private',
                            label: $t('private')
                          },
                          {
                            value: 'realm',
                            label: $t('realm') + ' ' + realmName
                          }]"
                            outlined name="contact_permission" item-text="label" item-value="value"
                            :label="$t('contact_permission')"></v-select>
                </validation-provider>
              </v-col>

              <v-col cols="6">

                <validation-provider v-slot="{ errors }" :name="$t('icon')" rules="required">
                  <v-autocomplete :label="$t('icon')" autocomplete="false" item-text="name" item-value="name" cypress="speed_dial_group"
                                  :disabled="loading" :error-messages="errors" v-model="form.icon" :items="getIcons" required
                                  outlined>
                    <template v-slot:item="data">
                      <v-icon class="mr-2"> {{ data.item.name }}</v-icon>
                      {{ data.item.name }}
                    </template>

                    <template v-slot:selection="data">
                      <v-icon class="mr-2"> {{ data.item.name }}</v-icon>
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>

                <b>{{ $t('background_color') }}</b>
                <br v-if="form.color" />
                <v-btn v-if="form.color" class="mb-2" color="red" small @click="onClearColorClick">{{
                    $t('clear_color')}}</v-btn>

                <v-color-picker :noAlpha="true" class="transparent-background full-width" :dark="$vuetify.theme.dark" cypress="speed_dial_colors"
                                hide-canvas hide-inputs v-model="form.color" hide-mode-switch hide-sliders swatches-max-height="100"
                                width="100%" show-swatches></v-color-picker>
              </v-col>
            </v-row>

            <v-row v-if="!form.is_folder">
              <v-col cols="6">
                <b>{{ $t('contacts') }}</b>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="loading" @click="addRow" icon v-on="on"  cypress="speed_dial_set_contact">
                      <v-icon color="error">
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('add_new')}}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row v-if="!form.is_folder">
              <v-col v-for="(contact,index) in contacts" :key="index" cols="12">

                <validation-provider v-slot="{ errors }" :name="$t('contact')" rules="required">

                  <v-text-field name="contact_name" :disabled="loading" outlined :error-messages="errors" readonly
                                :value="contact.name">

                      <span slot="append" class="pointer" @click="chooseContact(contact, index)" cypress="speed_dial_bind_contact">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="green" v-bind="attrs" v-on="on">
                              mdi-book-account
                            </v-icon>
                          </template>
                          <span> {{ $t('bind_contact')}}</span>
                        </v-tooltip>
                      </span>

                    <div class="button-position" slot="append-outer" v-if=" contacts.length > 1">
                      <v-btn x-small color="error" :disabled="loading" fab elevation="0" @click="deleteRow(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>

                    </div>

                  </v-text-field>

                </validation-provider>

              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-btn class="mr-5" color="passive_button" :disabled="loading" @click="onCancel()">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn :disabled="loading" type="submit" color="red">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-form>

        </validation-observer>

      </v-card-text>
    </v-card>

  </div>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'
import iconsHelper from '@/helpers/icons.helper'

import DialogShared from '@/app/shared/dialog/DialogShared'
import ContactBookComponent from '@/app/widgets/speed-dial-widget/components/ContactBookComponent'

export default {

  name: 'SpeedDialFormComponent',

  props: ['entity', 'realmName', 'parentId', 'items'],

  components: {
    ValidationObserver,
    ValidationProvider,
    DialogShared,
    ContactBookComponent
  },

  computed: {
    getIcons () {
      return iconsHelper.getIcons()
    },

    getItems () {
      return this.items.filter(o => o.is_folder === 1).filter(o => o.id !== this.form.id)
    }

  },

  data: () => ({

    contactBookDialog: false,

    defaultColor: '#424242',
    currentIndex: 0,
    contacts: [],
    loading: false,

    form: {
      id: null,
      icon: 'mdi-phone',
      order_by: 1,
      name: null,
      contact_book_ids: [],
      color: null,
      is_folder: false,
      parent_id: null,
      hot_contact_permission: 'private',
      realm: null
    }
  }),

  mounted () {
    if (this.entity) {
      this.form = Object.assign({}, this.entity)

      this.entity.contact_books.forEach((o) => {
        this.contacts.push({
          id: o.id,
          name: o.phone.name
        })
      })
    } else {
      this.form = {
        id: null,
        icon: 'mdi-phone',
        order_by: 1,
        contact_book_ids: [],
        name: null,
        color: {
          hex: this.defaultColor
        },
        realm: null,
        parent_id: this.parentId,
        is_folder: false,
        hot_contact_permission: 'private'

      }
      this.contacts.length = 0
    }
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    chooseContact (contact, index) {
      this.contactBookDialog = true
      this.currentIndex = index
    },

    bindContact (contact) {
      this.contacts.splice(this.currentIndex, 1, {
        ...{
          id: contact.id,
          name: contact.phone.name
        }
      })
      this.contactBookDialog = false
    },

    deleteRow (index) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('contact') })).then(confirmed => {
        if (confirmed) {
          this.contacts.splice(index, 1)
        }
      }
      )
    },

    onClearColorClick () {
      this.form.color = this.defaultColor
    },

    addRow () {
      this.contacts.push({})
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.loading = true

      if (this.form.hot_contact_permission === 'realm') {
        this.form.realm = this.realmName
      } else {
        this.form.realm = null
      }

      if (!this.form.id) {
        this.form.color = this.form.color.hex
      }

      this.form.contact_book_ids = this.contacts.map((object) => {
        return object.id
      })
      // clear contacts if its folder
      if (this.form.is_folder) {
        this.form.contact_book_ids = []
      }

      if (this.form.id) {
        apiService.speedDial.edit(this.entity.id, this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('updated')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      } else {
        apiService.speedDial.create(this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('created')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
