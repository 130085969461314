import Vue from 'vue'
import scrolldiv from 'vue-scroll-div'
import store from '@/store'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify.plugin'

import event from '@/plugins/event.plugin'
import router from '@/router'
import i18n from '@/plugins/i18n'
import permissionsMixin from '@/mixins/permissions.mixin'
import rolesMixin from '@/mixins/roles.mixin'
import offlineMixin from '@/mixins/offline.mixin'
import filters from '@/filters'
import confirm from '@/plugins/confirm.plugin'
import uuid from '@estudioliver/vue-uuid-v4'
import socket from '@/plugins/socket.plugin'
import moment from 'moment'

Vue.config.productionTip = false

/* Global mixins */
Vue.mixin(permissionsMixin)
Vue.mixin(rolesMixin)
Vue.mixin(offlineMixin)

/* Adding plugins */

Vue.use(event)
Vue.use(filters)
Vue.use(confirm)
Vue.use(scrolldiv)
Vue.use(uuid)
Vue.use(socket)
Vue.prototype.$moment = moment

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
