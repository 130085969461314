var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"scrollable":"","title":_vm.$t('log_view_full_log')},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('LogViewerViewComponents',{attrs:{"entity":_vm.logData},on:{"onCancel":function($event){_vm.dialog = false}}})],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('v-text-field',{attrs:{"name":"email","label":_vm.$t('email'),"clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"name":"sipAddress","label":_vm.$t('log_search_sip_address'),"clearable":""},model:{value:(_vm.sipAddress),callback:function ($$v) {_vm.sipAddress=$$v},expression:"sipAddress"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"name":"method","label":_vm.$t('method'),"clearable":""},model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"name":"position","label":_vm.$t('position'),"clearable":""},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})],1),_c('v-col',{staticClass:"col-1"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.filterLogs}},[_vm._v("Filter")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-2"},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dateFrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateFrom=$event},"update:return-value":function($event){_vm.dateFrom=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","label":_vm.$t('log_date_picker_from'),"clearable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDate(null)}}},[_vm._v(" "+_vm._s(_vm.$t('clear'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDate(_vm.dateFrom)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c('v-col',{staticClass:"col-2"},[_c('v-menu',{ref:"dateMenuTo",attrs:{"close-on-content-click":false,"return-value":_vm.dateTo,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateTo=$event},"update:return-value":function($event){_vm.dateTo=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","label":_vm.$t('log_date_picker_to'),"clearable":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.dateMenuTo),callback:function ($$v) {_vm.dateMenuTo=$$v},expression:"dateMenuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDateTo(null)}}},[_vm._v(" "+_vm._s(_vm.$t('clear'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDateTo(_vm.dateTo)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c('v-col',{staticClass:"col-2"},[_c('v-select',{staticClass:"ml-auto",attrs:{"label":_vm.$t('time_interval'),"items":_vm.timeIntervals},model:{value:(_vm.timeInterval),callback:function ($$v) {_vm.timeInterval=$$v},expression:"timeInterval"}})],1),_c('v-col',{staticClass:"col-2"},[_c('v-select',{staticClass:"ml-auto",attrs:{"label":_vm.$t('log_level'),"items":_vm.logLevels},model:{value:(_vm.logLevel),callback:function ($$v) {_vm.logLevel=$$v},expression:"logLevel"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredLogItems,"items-per-page":100,"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 200, 500, 1000, -1]
        },"server-items-length":_vm.totalCount},on:{"click:row":_vm.handleClick,"update:options":_vm.pagination}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }