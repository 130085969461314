<template>
  <div>
    <DialogShared v-model="dialog" scrollable :title="$t('log_view_full_log')">
      <LogViewerViewComponents :entity="logData" @onCancel="dialog = false" />
    </DialogShared>
    <v-row class="align-center">
      <v-col>
        <v-text-field
          name="email"
          v-model="email"
          :label="$t('email')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="sipAddress"
          v-model="sipAddress"
          :label="$t('log_search_sip_address')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="method"
          v-model="method"
          :label="$t('method')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="position"
          v-model="position"
          :label="$t('position')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="col-1">
        <v-btn color="success" @click="filterLogs">Filter</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="dateFrom"
              prepend-icon="mdi-calendar"
              readonly
              :label="$t('log_date_picker_from')"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(null)"
            >
              {{ $t('clear') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(dateFrom)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-2">
        <v-menu
          ref="dateMenuTo"
          v-model="dateMenuTo"
          :close-on-content-click="false"
          :return-value.sync="dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="dateTo"
              prepend-icon="mdi-calendar"
              readonly
              :label="$t('log_date_picker_to')"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDateTo(null)"
            >
              {{ $t('clear') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDateTo(dateTo)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-2">
        <v-select class="ml-auto"
          :label="$t('time_interval')"
          v-model="timeInterval"
          :items="timeIntervals"
        ></v-select>
      </v-col>
      <v-col class="col-2">
        <v-select class="ml-auto"
          :label="$t('log_level')"
          v-model="logLevel"
          :items="logLevels"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="filteredLogItems"
          :items-per-page="100"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 200, 500, 1000, -1]
          }"
          :server-items-length="totalCount"
          @click:row="handleClick"
          @update:options="pagination"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import apiService from '@/modules/api/csp'
import DialogShared from '@/app/shared/dialog/DialogShared'
import LogViewerViewComponents from '@/app/widgets/log-viewer-widget/components/LogViewerViewComponents'

export default {
  name: 'LogosSettingsTab',
  components: { LogViewerViewComponents, DialogShared },
  data: (vm) => ({
    email: '',
    method: '',
    dialog: false,
    position: '',
    totalCount: 0,
    sipAddress: '',

    logLevel: '',
    logLevels: [
      {
        value: 'info',
        text: 'INFO'
      },
      {
        value: 'warning',
        text: 'WARNING'
      },
      {
        value: 'critical',
        text: 'CRITICAL'
      }
    ],
    timeInterval: '',
    timeIntervals: [
      {
        value: 3600,
        text: 'Last hour'
      },
      {
        value: 3 * 3600,
        text: 'Last 3 hours'
      },
      {
        value: 6 * 3600,
        text: 'Last 6 hours'
      },
      {
        value: 12 * 3600,
        text: 'Last 12 Hours'
      },
      {
        value: 24 * 3600,
        text: 'Last 24 hours'
      }
    ],

    paginationData: {},
    filteredLogItems: [],

    logData: null,
    loading: false,

    dateTo: '',
    dateFrom: '',

    dateMenu: null,
    dateMenuTo: null,

    headers: [
      { text: vm.$t('email'), value: 'email', sortable: false },
      { text: vm.$t('sip_address'), value: 'sipAddress', sortable: false },
      { text: vm.$t('method'), value: 'method', sortable: false },
      { text: vm.$t('position'), value: 'position', sortable: false },
      { text: vm.$t('log_level'), value: 'logLevel', sortable: false },
      { text: vm.$t('date'), value: 'date', sortable: false, width: '30%' }
    ]
  }),
  methods: {
    async pagination (data) {
      this.paginationData = data

      this.getLogsFromDate()
    },
    handleClick (logData) {
      this.dialog = true
      this.logData = { ...logData }
    },
    async getLogsFromDate () {
      this.loading = true

      const filter = this.getFilter()

      const query = {
        ...filter,
        page: this.paginationData.page,
        itemsPerPage: this.paginationData.itemsPerPage
      }

      let logs = await apiService.activityLogs.search(query)

      if (!logs.status) return

      logs = logs.data

      if (!logs || !Array.isArray(logs)) return

      this.totalCount = logs[1]

      logs = logs[0]

      this.filteredLogItems = logs.map(log => ({
        url: log.url,
        date: new Date(parseInt(log.write_time)),
        type: log.type,
        email: log.email,
        method: log.method,
        request: log.request,
        position: log.position,
        response: log.response,
        logLevel: log.log_level,
        createdAt: log.created_at,
        writeTime: log.write_time,
        sipAddress: log.sip_address,
        requestHeaders: log.request_headers,
        responseHeaders: log.response_headers
      }))

      this.loading = false
    },
    saveDate (date) {
      this.$refs.dateMenu.save(date)
    },
    saveDateTo (date) {
      this.$refs.dateMenuTo.save(date)
    },
    getFilter () {
      return {
        email: this.email || '',
        method: this.method || '',
        date_to: this.dateTo ? new Date(this.dateTo).getTime() : '',
        position: this.position || '',
        log_level: this.logLevel || '',
        date_from: this.dateFrom ? new Date(this.dateFrom).getTime() : '',
        sip_address: this.sipAddress || '',
        time_interval: this.timeInterval || ''
      }
    },
    filterLogs () {
      this.getLogsFromDate()
    }
  }
}
</script>
