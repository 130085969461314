var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"1000","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('RingtoneForm',{key:_vm.formKey,on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('global_settings'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":_vm.onCreate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('upload')))])])],1)],1),_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('changes_will_be_applied_after_relogin_or_page_refresh'))+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.switchFilter(false)}}},[_vm._v(" "+_vm._s(_vm.$t('only_active')))]),_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.switchFilter(true)}}},[_vm._v(" "+_vm._s(_vm.$t('all')))]),_c('v-data-table',{staticClass:"transparent-background mt-2",attrs:{"dark":_vm.$vuetify.theme.dark,"items":_vm.list,"loading":_vm.loading,"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onMarkAsDefault(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('make_default')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteRingtone(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('delete')))])])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.is_default",fn:function(ref){
var item = ref.item;
return [(item.is_default)?_c('span',[_vm._v(" Active ")]):_vm._e()]}},{key:"item.file_url",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.file_name)+" ")]),_c('audio-player',{key:_vm.formKey+'_'+item.file_id,attrs:{"flat":true,"auto-play":false,"file":item.file_url}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }