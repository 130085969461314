
const localStorageService = (function () {
  const _service = this

  function _getService () {
    return _service
  }

  function _setUserTokens ({ userAccessToken, refreshToken }) {
    localStorage.setItem('user_access_token', userAccessToken)
    localStorage.setItem('refresh_token', refreshToken)
  }

  function _setAccountId ({ accountId }) {
    localStorage.setItem('account_id', accountId)
  }

  function _getAccountId () {
    return localStorage.getItem('account_id')
  }

  function _getUserAccessToken () {
    return localStorage.getItem('user_access_token')
  }

  function _getRefreshToken () {
    return localStorage.getItem('refresh_token')
  }

  function _clearLocalStorageOnLogout () {
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user_access_token')
    localStorage.removeItem('userId')
  }

  function _getMapStyle () {
    return localStorage.getItem('map_style')
  }

  function _setMapStyle (val) {
    localStorage.setItem('map_style', val)
  }

  function _setThemeStyle (val) {
    localStorage.setItem('theme_style', val)
  }

  function _getThemeStyle () {
    const val = localStorage.getItem('theme_style')

    if (!val) return true

    return val.toLowerCase() === 'true'
  }

  return {
    getService: _getService,
    getMapStyle: _getMapStyle,
    setMapStyle: _setMapStyle,
    setUserTokens: _setUserTokens,
    getUserAccessToken: _getUserAccessToken,
    getRefreshToken: _getRefreshToken,
    clearLocalStorageOnLogout: _clearLocalStorageOnLogout,
    setAccountId: _setAccountId,
    getAccountId: _getAccountId,
    setThemeStyle: _setThemeStyle,
    getThemeStyle: _getThemeStyle
  }
})()

export default localStorageService
