<template>

  <v-card tile flat color="secondary_card">
    <v-card-text>
      <validation-observer ref="observer">

        <v-form @submit.prevent="onSubmit" novalidate>
          <v-row dense>
            <v-col>
              <validation-provider v-slot="{ errors }" name="name" rules="required">
                <v-text-field outlined name="name" :label="$t('name')" v-model="form.name" :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>
          <v-col>
          </v-col>
          <v-row dense>
            <v-col>
              <validation-provider v-slot="{ errors }" name="layer_id" rules="required">
                <v-text-field outlined name="layer_id" :label="$t('layer_id')" v-model="form.layer_id"
                  :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <validation-provider v-slot="{ errors }" name="client_id" rules="required">
                <v-text-field outlined name="client_id" :label="$t('client_id')" v-model="form.client_id"
                  :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <validation-provider v-slot="{ errors }" name="client_secret" rules="required">
                <v-text-field outlined name="client_secret" :label="$t('client_secret')" v-model="form.client_secret"
                  :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-switch v-model="form.is_active" :label="$t('enable_layer')" persistent-hint />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-btn class="mr-5" color="passive_button" :disabled="loading" @click="onCancel()">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn :disabled="loading" type="submit" color="red">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>

    </v-card-text>
  </v-card>

</template>

<script>

// import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import apiService from '@/modules/api/csp'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'ChatFormComponent',

  props: {
    entity: Object,
    isEditModalMode: Boolean
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    search: '',
    loading: false,
    form: {
      name: null,
      realm: null,
      client_id: null,
      client_secret: null,
      is_active: null
    }
  }),

  computed: {},

  created () {
    if (this.entity) {
      this.form = { ...this.entity }
    }
  },

  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      this.loading = true
      this.form.is_active = Boolean(this.form.is_active)
      this.form.refresh_rate = Number(this.form.refresh_rate)
      if (this.isEditModalMode) {
        try {
          const query = { id: this.form.id }
          const response = await apiService.mapLayersSettings.edit(query, this.form)
          this.$emit('updateInCurrentList', response.data)
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('updated')
          })
        } catch (error) {
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: this.$t('something_went_wrong_please_try_again_later')
          })
        } finally {
          this.loading = false
        }
        return
      }

      try {
        const response = await apiService.mapLayersSettings.create(this.form)
        this.$emit('addToCurrentList', response.data)
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('created')
        })
      } catch (error) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      } finally {
        this.loading = false
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped></style>
