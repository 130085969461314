<template>
  <div v-if="authUserGetter">

    {{ $t('welcome') }}, {{ authUserGetter.name.toUpperCase() }}

    <logout-button/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/* Buttons */
import LogoutButton from '@/app/widgets/top-bar-widget/buttons/LogoutButton'

export default {
  name: 'TopBarComponent',
  components: {
    LogoutButton
  },
  computed: {
    ...mapGetters('global', ['authUserGetter'])
  }
}
</script>

<style scoped>

</style>
