<template>
  <div>

    <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
      <ProxyFormComponent :key="formKey" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit"/>
    </DialogShared>

    <div class="text-right">
      <v-btn @click="onCreate" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add') }}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table :disable-pagination="true" :hide-default-footer="true" class="transparent-background"
                  :items="items"
                  :loading="loading"

                  :headers="headers"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-btn :disabled="loading" class="mr-2" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn :disabled="loading" class="mr-1" color="red" small @click.stop="onDelete(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import ProxyFormComponent from '@/app/widgets/proxy-widget/components/ProxyFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {

  name: 'ProxyDWidget',

  components: { ProxyFormComponent, DialogShared },
  data: (vm) => ({

    entity: {},
    formKey: 0,
    dialogTitle: vm.$t('create'),
    dialog: false,
    items: [],

    loading: false,

    headers: [
      { text: vm.$t('domain'), value: 'domain', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  async mounted () {
    await this.load()
  },

  computed: {},

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    refreshForm () {
      this.formKey += 1
    },

    async load () {
      this.loading = true

      await apiService.proxy.load().then((result) => {
        this.items = result.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.load()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update')
      this.refreshForm()
      this.entity = item
      this.dialog = true
    },

    onCreate () {
      this.entity = null
      this.dialogTitle = this.$t('add')

      this.refreshForm()
      this.dialog = true
    },

    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('proxy') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          apiService.proxy.delete(item.id).then(response => {
            this.load()
          })
        }
      })
    }
  }

}
</script>
