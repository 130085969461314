export default function mapLayersSettingsModule (instance) {
  /** FOR DETAILED documentation please visit http://localhost:3000/docs#/Map%20layers/MapLayersController_findAllByRealmForAdmin */
  return {

    /**
     * get all layers per selected REALM
     * @returns {Promise<*>}
     */
    get: async (queryParams) => {
      const resource = '/map-layers/admin'
      const response = await instance.get(resource, {
        params: {
          ...queryParams
        }
      })
      return response
    },
    /**
     * create new layer by selected REALM
     * @returns {Promise<*>}
     */
    create: async (body) => {
      const resource = '/map-layers'
      const response = await instance.post(resource, body)
      return response
    },
    /**
     * update layer by selected REALM
     * @returns {Promise<*>}
     */
    edit: async (query, body) => {
      const resource = `/map-layers/${query.id}`
      const response = await instance.patch(resource, body)
      return response
    },
    /**
   * delete layer by it's id
   * @returns {Promise<*>}
   */
    delete: async (id) => {
      const resource = `/map-layers/${id}`
      const response = await instance.delete(resource)
      return response
    }
  }
}
