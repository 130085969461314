export default function speedDialApiModule (instance) {
  return {

    /**
     * Returns Contacts Hot Buttons.
     *
     * @return { Promise } response
     */
    get: async (payload) => {
      const resource = `/hot-contact-book-buttons?timestamp=${new Date().getTime()}`

      const response = await instance.get(resource, {
        params: payload
      })

      return response
    },

    /**
     * Return contacts hot buttons
     * @param payload
     * @returns {Promise<*>}
     */
    tree: async (payload) => {
      const resource = `/hot-contact-book-buttons/tree?timestamp=${new Date().getTime()}`

      const response = await instance.get(resource, {
        params: payload
      })

      return response
    },

    /**
     * Create Contacts Hot Buttons.
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    create: async (data) => {
      const resource = '/hot-contact-book-buttons'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Edit Contacts Hot Buttons by id.
     *
     * @param { Number } id
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    edit: async (id, data) => {
      const resource = `/hot-contact-book-buttons/${id}`

      data.is_folder = Boolean(data.is_folder)

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete Contacts Hot Buttons by id.
     *
     * @param { Number } id
     *
     * @return { Promise } response
     */
    delete: async (id) => {
      const resource = `/hot-contact-book-buttons/${id}`

      const response = await instance.delete(resource)

      return response
    },

    /**
     * Delete all speed dials
     * @param realmName
     * @returns {Promise<*>}
     */
    deleteAll: async (realmName) => {
      const resource = '/hot-contact-book-buttons/all'

      const response = await instance.delete(resource, {
        params: {
          realm_name: realmName
        }
      })

      return response
    },

    /**
     * Delete selected speed dials
     * @param contactIds
     * @param realmName
     * @returns {Promise<*>}
     */
    deleteSelected: async (ids, realmName) => {
      const resource = '/hot-contact-book-buttons'

      const response = await instance.delete(resource, {
        data: {
          hot_contact_book_button_ids: ids
        },
        params: {
          realm_name: realmName
        }
      })

      return response
    }
  }
}
