<template>
  <validation-observer ref="observer" >
    <v-form class="pt-4" :disabled="disabled" @submit.prevent="onSubmit" novalidate>
      <v-row dense>
        <v-col>

          <validation-provider v-slot="{ errors }" vid="type" :name="$t('type')" rules="required">

            <v-select
                :label="$t('type')"
                v-model="form.ringtone_type_id"
                :items="types"
                item-value="id"
                item-text="description" :error-messages="errors"
            ></v-select>

          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
            <validation-provider v-slot="{ errors }" vid="ringtone" :name="$t('file')" rules="required">

              <v-file-input
                  v-model="form.ringtone"
                  truncate-length="15"
                  name="ringtone"
                  accept=".mp3,.wav,.aac,.mpga"
                  :label="$t('choose_file')"
                  show-size
                  :disabled="disabled" :error-messages="errors"
              ></v-file-input>

            </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="disabled"  @click="onCancel()">
            {{ $t('cancel')}}
          </v-btn>
          <v-btn :disabled="disabled" type="submit" color="red"  >
            {{ $t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>
<script>
import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'
import apiService from '@/modules/api/csp'

export default {
  name: 'UploadGlobalRingtoneFormComponent',
  props: ['entity'],
  data: () => ({
    form: {
      ringtone_type_id: '',
      ringtone: null
    },

    types: [],

    disabled: false
  }),
  components: {
    ValidationObserver,
    ValidationProvider

  },
  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.disabled = true

        apiService.globalRingtone.upload(this.form).then((response) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('ringtone_uploaded')
          })

          this.disabled = false
          this.$emit('onSubmit')
        }).catch((error) => {
          this.disabled = false

          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      }
    },
    onCancel () {
      this.$emit('onCancel')
    },

    async getTypes () {
      await apiService.globalRingtone.types().then((response) => {
        this.types = response.data
      })
    }

  },
  mounted () {
    this.getTypes()
  }

}
</script>
