var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"900","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ContactFormComponent',{key:_vm.formKey,attrs:{"realm":_vm.realmName,"entity":_vm.entity},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('DialogShared',{attrs:{"max-width":"900","scrollable":"","title":_vm.$t('import')},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}},[_c('ImportContactBooksModal',{key:_vm.formKey,attrs:{"realm":_vm.realmName},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('contacts'))+" "+_vm._s(_vm.realmName)+" ")]),_c('v-spacer'),_c('v-select',{staticClass:"mt-2",staticStyle:{"max-width":"300px"},attrs:{"label":_vm.$t('realm'),"items":_vm.realms},on:{"change":function($event){return _vm.load()}},model:{value:(_vm.realmName),callback:function ($$v) {_vm.realmName=$$v},expression:"realmName"}}),(_vm.realmName)?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","loading":_vm.downloading},on:{"click":_vm.downloadContacts}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,false,2783003173)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('download')))])])],1)],1):_vm._e(),(_vm.realmName)?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onImportClick}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-database-arrow-left ")])]}}],null,false,3316129910)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('import')))])])],1)],1):_vm._e(),(_vm.realmName)?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onCreate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,false,1287216139)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add')))])])],1)],1):_vm._e()],1),(_vm.realmName)?_c('v-data-table',{staticClass:"transparent-background",attrs:{"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"item-key":"id","selectable-key":"id","single-select":false,"show-select":"","show-expand":"","single-expand":"","expanded":_vm.expanded,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex pb-1"},[(_vm.$canAny('can_delete_contact') && _vm.selected.length > 0 )?_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.loading,"color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteSelected()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$t('delete_selected'))+" ")]}}],null,false,289199862)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1):_vm._e(),_c('v-spacer'),(_vm.$canAny('can_delete_contact'))?_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.loading,"color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteAll()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$t('delete_all_contacts'))+" ")]}}],null,false,190134290)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete_all_contacts')))])])],1):_vm._e()],1)]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"name":"search","clearable":true,"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.contact_permission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact_permission)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1 my-1",attrs:{"color":"grey","small":"","depressed":"","min-width":"0","width":"30","height":"30"}},[_c('v-icon',{staticClass:"grey",attrs:{"small":""}},[_vm._v(" mdi-account-box-outline ")])],1),(item.phone)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.phone.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.phone.name))])]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.phone.phone))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.phone.phone))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])],1),(_vm.$canAny('can_delete_contact'))?_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.loading,"color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":"6"}},[_c('v-simple-table',{staticClass:"expanded-table",attrs:{"fixed-header":""}},[_c('tbody',[_vm._l((item.contact_book_phones),function(phones){return [(!phones.is_primary)?_c('tr',{key:phones.id,staticClass:"pa-0"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('name'))+": "),_c('span',{attrs:{"title":phones.name}},[_vm._v(" "+_vm._s(phones.name)+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t('phone'))+": "),_c('span',{attrs:{"title":phones.phone}},[_vm._v(" "+_vm._s(phones.phone)+" ")])])]):_vm._e()]})],2)])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }