var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("global_settings"))+" ")])]),_c('DialogShared',{attrs:{"max-width":"900","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('GlobalSettingsForm',{key:_vm.formKey,attrs:{"entity":_vm.entity},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('v-alert',{attrs:{"dense":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("changes_will_be_applied_after_relogin_or_page_refresh"))+" ")]),_c('v-data-table',{staticClass:"transparent-background",attrs:{"disable-pagination":true,"hide-default-footer":true,"items":_vm.settings,"loading":_vm.loading,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.type == 'checkbox')?_c('span',[_c('v-switch',{attrs:{"dense":"","flat":"","inset":"","label":item.value.active ? _vm.$t('enabled') : _vm.$t('disabled')},on:{"change":function($event){return _vm.onItemChange(item)}},model:{value:(item.value.active),callback:function ($$v) {_vm.$set(item.value, "active", $$v)},expression:"item.value.active"}})],1):(item.type == 'number')?_c('span',[_vm._v(" "+_vm._s(item.value.current)+" ")]):(item.type == 'url')?_c('span',[_vm._v(" "+_vm._s(item.value.url)+" ")]):(item.type == 'text')?_c('span',[_vm._v(" "+_vm._s(item.value.text)+" ")]):(item.type == 'color')?_c('span',{staticClass:"d-flex flex-row align-center"},[(item.value.text)?_c('v-sheet',{staticClass:"mr-2 my-auto",attrs:{"width":"20","height":"20","rounded":"","elevation":"0","color":item.value.text}}):_vm._e(),_vm._v(" "+_vm._s(item.value.text)+" ")],1):_c('span',[_vm._v(" Unsupported Type "+_vm._s(item.type)+" with value "+_vm._s(item.value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }