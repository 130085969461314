<template>

  <v-card tile flat color="secondary_card">
    <v-card-text>
      <validation-observer ref="observer" >

        <v-form @submit.prevent="onSubmit" novalidate>

          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="name"
                :name="$t('call_line_name')"
                :rules="{ required: true }"
              >
                <v-text-field v-model="form.name" :placeholder="$t('call_line_name')" :error-messages="errors" />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="key"
                :name="$t('call_line_key')"
                :rules="{ required: true }"
              >
                <v-text-field v-model="form.key" :placeholder="$t('call_line_key')" :error-messages="errors" />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="value"
                :name="$t('call_line_value')"
                :rules="{ required: true }"
              >
                <v-text-field v-model="form.value" :placeholder="$t('call_line_value')" :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
                {{ $t('cancel')}}
              </v-btn>
              <v-btn :disabled="loading" type="submit" color="red"  >
                {{ $t('save')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      </validation-observer>

    </v-card-text>
  </v-card>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'CallLinesForm',

  props: ['entity', 'realmName'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    search: '',
    loading: false,
    form: {
      key: '',
      name: '',
      value: ''
    }
  }),

  computed: {},

  mounted () {
    this.form = Object.assign({}, this.entity)
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.loading = true

      if (this.entity.id) {
        apiService.callLines.edit(this.entity.id, this.form, this.realmName).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('call_lines_updated')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })

        return
      }

      apiService.callLines.add(this.form, this.realmName).then((response) => {
        this.loading = false

        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('call_lines_added')
        })

        this.$emit('onSubmit')
      }).catch((error) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: error.response.data.message[0]
        })
      })
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
