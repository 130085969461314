var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('validation-observer',{ref:"observer"},[_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"disabled":_vm.loading,"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{staticClass:"pb-4"},[_c('v-col',{staticClass:"text-right pt-2",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"rounded":true,"small":"","color":"success","cypress":"add_new_number"},on:{"click":_vm.addRow}},[_vm._v(" "+_vm._s(_vm.$t('add_new_number' ))+" "),_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('contact_permission'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.realm)?_c('v-select',{attrs:{"cypress":"contact_permission","error-messages":errors,"items":[ {
      value: 'private',
      label: _vm.$t('private')
    },
    {
      value: 'realm',
      label: _vm.$t('realm') + ' ' + _vm.realm
    }],"outlined":"","name":"contact_permission","item-text":"label","item-value":"value","label":_vm.$t('contact_permission')},model:{value:(_vm.form.contact_permission),callback:function ($$v) {_vm.$set(_vm.form, "contact_permission", $$v)},expression:"form.contact_permission"}}):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('contact_groups'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"dark":_vm.$vuetify.theme.dark,"error-messages":errors,"cypress":"contact_groups","items":_vm.contactGroups || [],"outlined":"","multiple":"","small-chips":"","name":"contact_groups","item-text":"name","item-value":"id","label":_vm.$t('contact_groups')},model:{value:(_vm.form.contact_book_group_ids),callback:function ($$v) {_vm.$set(_vm.form, "contact_book_group_ids", $$v)},expression:"form.contact_book_group_ids"}})]}}])}),_c('v-radio-group',{model:{value:(_vm.form.is_primary),callback:function ($$v) {_vm.$set(_vm.form, "is_primary", $$v)},expression:"form.is_primary"}},[_vm._l((_vm.form.contact_book_phones),function(item,index){return [_c('v-sheet',{key:index,staticClass:"pa-4 mt-2",attrs:{"color":"transparent","elevation":"3","rounded":true}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"8"}},[_c('v-radio',{key:index,staticClass:"my-0",attrs:{"value":index,"label":_vm.$t('make_primary'),"cypress":("contact_book_primary_" + index)},on:{"click":function($event){return _vm.changePrimary()}}})],1),_c('v-col',{staticClass:"text-right pb-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.removeRow(index)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('remove_phone_number')))])])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":("contact_phone_number_" + index),"name":_vm.$t('phone_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{key:("contact_phone_number_" + index),attrs:{"error-messages":errors,"name":"phone_number","label":_vm.$t('phone_number'),"required":"","outlined":"","cypress":("contact_phone_number_" + index)},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":("contact_name_" + index),"name":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{key:("contact_name_" + index),attrs:{"error-messages":errors,"name":"name","label":_vm.$t('name'),"required":"","outlined":"","cypress":("contact_name_" + index)},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}}],null,true)})],1)],1)],1)]})],2)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"passive_button","disabled":_vm.loading},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"error","cypress":"submit_contact"},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }