<template>
  <div>

    <DialogShared v-model="dialog" max-width="1000" scrollable :title="dialogTitle">
      <UserForm :key="formKey" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <DialogShared v-model="importUsersDialog" max-width="1000" :title="$t('import_users')" >
      <ImportUsersFormComponent :key="formKey" @onCancel="importUsersDialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <DialogShared v-model="sipAddressDialog" max-width="1000" :title="$t('sip_address')" >
      <UserSipAddressComponent :entity="entity" :key="formKey" @onCancel="sipAddressDialog = false"  />
    </DialogShared>

    <DialogShared v-model="accountSettingsDialog" max-width="1000" :title="$t('account_settings')">
      <UserAccountSettingList :key="formKey" :entity="entity" @onCancel="accountSettingsDialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <DialogShared v-model="importUsersFromIPPBXDialog" max-width="1200" :title="$t('import_from_ippbx')" >
      <ImportUsersFromIPPBXForm :key="formKey" @onCancel="importUsersFromIPPBXDialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <div class="d-flex align-center" >

      <h2 >
        {{ $t('users_settings')}}
      </h2>

      <v-spacer />

      <v-btn v-if="$can('can_import_users')" class="ml-auto" color="error" @click="onImportFromIPPBX">{{ $t('import_from_ippbx') }}</v-btn>

      <v-btn v-if="$can('can_change_admin_users')" @click="onCreate" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add_new')}}</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="$can('can_import_users')" @click="onImportUsers" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-database-arrow-left
            </v-icon>
          </template>
          <span> {{ $t('import_users')}}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table class="transparent-background"  :dark="$vuetify.theme.dark"
                  :items="users"
                  :loading="loading"
                  :options.sync="options"

                  :headers="headers"
                  :footer-props="{itemsPerPageOptions : [5,10,15]}"
    >

      <template v-slot:top>

        <v-text-field clearable @keyup.enter="loadUsers" @click:clear="refreshUsersList" v-model="search" :label="$t('search')"></v-text-field>

      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="$can('can_change_admin_users')" class="mr-1" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span> {{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$can('can_change_admin_users')" class="mr-1" color="primary" small @click.stop="onSipAddressEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-phone-plus
              </v-icon>
            </template>
            <span> {{ $t('sip_address')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$can('can_change_admin_users')" color="primary" class="mr-1" small @click.stop="onAccountSettings(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-cog-outline
              </v-icon>
            </template>
            <span>{{ $t('account_settings')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$can('can_change_admin_users')" color="error" small @click.stop="deleteUser(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'
import UserForm from '@/app/widgets/users-widget/components/UserFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'
import ImportUsersFormComponent from '@/app/widgets/users-widget/components/ImportUsersFormComponent'
import UserSipAddressComponent from '@/app/widgets/users-widget/components/UserSipAddressComponent'
import UserAccountSettingList from '@/app/widgets/users-widget/components/UserAccountSettingList'
import ImportUsersFromIPPBXForm from '@/app/widgets/users-widget/components/ImportUsersFromIPPBXForm'

export default {

  name: 'LayoutTab',

  components: { UserSipAddressComponent, ImportUsersFormComponent, DialogShared, UserForm, UserAccountSettingList, ImportUsersFromIPPBXForm },
  data: (vm) => ({

    importUsersDialog: false,
    sipAddressDialog: false,
    accountSettingsDialog: false,
    importUsersFromIPPBXDialog: false,

    search: '',
    entity: null,
    formKey: 0,
    dialogTitle: vm.$t('create_user'),
    dialog: false,

    users: [],
    options: {
      page: 1,
      itemsPerPage: 10
    },
    loading: false,
    headers: [

      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('email'), value: 'email', sortable: false },
      { text: vm.$t('roles'), value: 'roles', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  watch: {

    options: {
      handler () {
        this.loadUsers()
      },
      deep: true
    }
  },

  mounted () {
    this.loadUsers()
    this.initModule()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    initModule () {
      apiService.roles.list().then((response) => {
        this.$store.dispatch('roles/roles', response.data)
      }).catch(() => {

      })

      apiService.permissions.list().then((response) => {
        this.$store.dispatch('roles/permissions', response.data)
      }).catch(() => {

      })
    },

    refreshForm () {
      this.formKey += 1
    },

    async loadUsers () {
      if (this.search !== '') {
        await this.searchUsers()
      } else {
        await this.getUsers()
      }
    },

    onSipAddressEdit (item) {
      apiService.users.get(item.id).then((response) => {
        this.refreshForm()
        this.entity = response.data

        this.sipAddressDialog = true
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onAccountSettings (item) {
      apiService.users.get(item.id).then((response) => {
        this.refreshForm()

        this.entity = response.data

        this.accountSettingsDialog = true
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async deleteUser (item) {
      this.loading = true
      await apiService.users.delete(item.id).then(() => {
        this.loading = false
        this.loadUsers()
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('user_deleted')
        })
      }).catch((error) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: error.response.data.message[0]
        })
      })
    },

    async getUsers () {
      this.loading = true

      await apiService.users.list().then((result) => {
        this.users = result.data

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onImportUsers () {
      this.refreshForm()
      this.importUsersDialog = true
    },

    async searchUsers () {
      this.loading = true

      await apiService.users.search({
        search: this.search
      }).then((result) => {
        this.users = result.data

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async onFormSubmit () {
      this.dialog = false
      this.importUsersDialog = false
      this.sipAddressDialog = false
      await this.loadUsers()
      await this.loadUsers()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update_user')

      apiService.users.get(item.id).then((response) => {
        this.refreshForm()
        this.entity = response.data

        this.dialog = true
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onCreate () {
      this.dialogTitle = this.$t('create_user')
      this.entity = null
      this.refreshForm()
      this.dialog = true
    },

    refreshUsersList () {
      this.search = ''
      this.loadUsers()
    },

    onImportFromIPPBX () {
      this.importUsersFromIPPBXDialog = true
    }
  }

}
</script>

<style scoped>

</style>
