<template>
  <div>

    <DialogShared v-model="userRealmSettingDialog" max-width="1000">
      <UserRealmSettingsList :key="'user_realm_'+formKey" :entity="entity" :realm_name="realm_name" />
    </DialogShared>

    <v-card tile flat color="secondary_card">

      <v-card-text>
        <v-data-table class="transparent-background"  :dark="$vuetify.theme.dark" :headers="headers"
            :items="realms"
            :loading="loading" :items-per-page="10"
        >
          <template v-slot:[`item.realm`]="{item}">
            {{ item.item }}
          </template>
          <template v-slot:[`item.actions`]="{item}">
              <v-btn class="mr-1" color="green" small  @click.stop="onAccountSettingsEdit(item.item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span> {{ $t('edit')}}</span>
                </v-tooltip>
              </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>

import UserRealmSettingsList from '@/app/widgets/users-widget/components/UserRealmSettingsList'

import { mapActions } from 'vuex'

import apiService from '@/modules/api/csp'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {
  name: 'UserAccountSettingList',

  props: ['entity'],

  components: {
    DialogShared,
    UserRealmSettingsList
  },

  data: (vm) => ({
    formKey: 0,
    headers: [
      { text: vm.$t('realm'), value: 'realm', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ],
    realms: [],
    realm_name: null,
    loading: false,

    userRealmSettingDialog: false

  }),
  computed: {

  },
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    onAccountSettingsEdit (realm) {
      this.realm_name = realm
      this.refreshComponent()
      this.userRealmSettingDialog = true
    },

    refreshComponent () {
      this.formKey += 1
    },

    loadRealms (userId) {
      this.loading = true

      apiService.sip.getUserRealms(userId).then((result) => {
        this.realms = result.filter(n => n != null).map((item) => ({ item }))
        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    }

  },

  mounted () {
    this.loadRealms(this.entity.id)
  }
}
</script>
