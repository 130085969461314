<template>
  <div>

    <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
      <ContactFormComponent :key="formKey" :realm="realmName" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit"/>
    </DialogShared>

    <DialogShared v-model="importDialog" max-width="900" scrollable :title="$t('import')">
      <ImportContactBooksModal :key="formKey" :realm="realmName" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <div class="d-flex align-center mb-2">

      <h2 >
        {{ $t('contacts')}} {{ realmName }}
      </h2>

      <v-spacer />

      <v-select style="max-width: 300px" class="mt-2"  @change="load()"
                :label="$t('realm')"
                v-model="realmName"
                :items="realms"
      ></v-select>

      <div class="text-right" v-if="realmName">
        <v-btn @click="downloadContacts" icon :loading="downloading">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">
                mdi-download
              </v-icon>
            </template>
            <span> {{ $t('download') }}</span>
          </v-tooltip>
        </v-btn>
      </div>

      <div class="text-right" v-if="realmName">
        <v-btn @click="onImportClick" icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">
                mdi-database-arrow-left
              </v-icon>
            </template>
            <span> {{ $t('import') }}</span>
          </v-tooltip>
        </v-btn>
      </div>

      <div class="text-right" v-if="realmName">
        <v-btn @click="onCreate" icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">
                mdi-plus
              </v-icon>
            </template>
            <span> {{ $t('add') }}</span>
          </v-tooltip>
        </v-btn>
      </div>

    </div>

    <v-data-table v-if="realmName"  class="transparent-background"
                  :items="items"
                  :search="search"
                  :loading="loading"
                  item-key="id" selectable-key="id" :single-select="false" v-model="selected" show-select show-expand
                  single-expand :expanded="expanded"
                  :headers="headers"
    >

      <template v-slot:[`top`]>

        <div class="d-flex pb-1" >

          <v-btn v-if="$canAny('can_delete_contact') && selected.length > 0 " :disabled="loading" class="mr-1" color="red" small @click.stop="onDeleteSelected()">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">

                <v-icon smalls v-bind="attrs" v-on="on">
                  mdi-delete
                </v-icon>

                {{ $t('delete_selected') }}
              </template>
              <span>{{ $t('delete') }}</span>
            </v-tooltip>
          </v-btn>

          <v-spacer />

          <v-btn v-if="$canAny('can_delete_contact')" :disabled="loading" class="mr-1" color="red" small @click.stop="onDeleteAll()">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">

                <v-icon smalls v-bind="attrs" v-on="on">
                  mdi-delete
                </v-icon>

                {{ $t('delete_all_contacts') }}
              </template>
              <span>{{ $t('delete_all_contacts') }}</span>
            </v-tooltip>
          </v-btn>

        </div>

      </template>

      <template v-slot:top>
        <v-text-field
          name="search"
          :clearable=true
          label="Search"
          v-model="search"
        ></v-text-field>
      </template>
      <template v-slot:[`item.contact_permission`]="{ item }">
        {{ item.contact_permission }}
      </template>

      <template v-slot:[`item.name`]="{ item }">

        <v-btn color="grey" small depressed min-width="0" width="30" height="30" class="mx-1 my-1">
          <v-icon class="grey" small>
            mdi-account-box-outline
          </v-icon>
        </v-btn>

        <v-tooltip bottom v-if="item.phone">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ item.phone.name  }}</span>
          </template>
          <span>{{ item.phone.name }}</span>
        </v-tooltip>

      </template>

      <template v-slot:[`item.phone`]="{ item }">

        <v-tooltip bottom v-if="item.phone">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ item.phone.phone  }}</span>
          </template>
          <span>{{ item.phone.phone }}</span>
        </v-tooltip>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-btn :disabled="loading" class="mr-2" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$canAny('can_delete_contact')" :disabled="loading" class="mr-1" color="red" small @click.stop="onDelete(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </v-btn>

      </template>

      <template v-slot:expanded-item="{ item }">
        <td colspan="6" class="pa-0">
          <v-simple-table fixed-header class="expanded-table">
            <tbody>
            <template v-for="phones in item.contact_book_phones">
              <tr class="pa-0" v-if="!phones.is_primary" :key="phones.id">
                <td>
                  {{ $t('name')}}:
                  <span :title="phones.name">
                    {{ phones.name  }}
                  </span>
                </td>
                <td>
                  {{ $t('phone')}}:
                  <span :title="phones.phone">
                    {{ phones.phone  }}
                  </span>
                </td>

              </tr>
            </template>
            </tbody>
          </v-simple-table>
        </td>
      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import ContactFormComponent from '@/app/widgets/contact-widget/components/ContactFormComponent'

import DialogShared from '@/app/shared/dialog/DialogShared'
import ImportContactBooksModal from '@/app/widgets/contact-widget/components/ImportContactBooks'

export default {

  name: 'ContactsWidget',

  components: { ImportContactBooksModal, ContactFormComponent, DialogShared },
  data: (vm) => ({

    expanded: [],
    entity: {},
    formKey: 0,
    dialogTitle: vm.$t('create'),
    dialog: false,

    importDialog: false,

    search: '',
    items: [],
    realms: [],
    realmName: '',

    loading: false,
    downloading: false,

    selected: [],

    headers: [
      { text: vm.$t('name'), value: 'phone.name', sortable: true },
      { text: vm.$t('phone'), value: 'phone.phone', sortable: true },
      { text: vm.$t('type'), value: 'contact_permission', sortable: true },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  async mounted () {
    await this.loadRealms()
  },

  computed: {},

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    onDeleteSelected () {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('selected_contacts') })).then(async confirmed => {
        if (confirmed) {
          this.loading = true

          await apiService.contactBook.deleteSelected(this.selected.map(o => o.id), this.realmName).then((result) => {
            this.loading = false
            this.selected = []
            this.load()
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('deleted')
            })
          }).catch(() => {
            this.loading = false
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: this.$t('something_went_wrong_please_try_again_later')
            })
          })
        }
      })
    },

    async onDeleteAll () {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('all_contacts_for_realm') })).then(async confirmed => {
        if (confirmed) {
          this.loading = true
          await apiService.contactBook.deleteAll(this.realmName).then((result) => {
            this.loading = false
            this.selected = []
            this.load()
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('deleted')
            })
            this.selected = []
          }).catch(() => {
            this.loading = false
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: this.$t('something_went_wrong_please_try_again_later')
            })
          })
        }
      })
    },

    refreshForm () {
      this.formKey += 1
    },

    onImportClick () {
      this.importDialog = true
    },

    async loadRealms () {
      this.loading = true
      await apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async load () {
      this.loading = true

      await apiService.contactBook.get({
        realm_name: this.realmName
      }).then((result) => {
        this.items = result.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.importDialog = false
      this.load()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update')
      this.refreshForm()
      this.entity = item
      this.dialog = true
    },

    onCreate () {
      this.entity = null
      this.dialogTitle = this.$t('add')

      this.refreshForm()
      this.dialog = true
    },

    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('contact') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          apiService.contactBook.delete(item.id).then(response => {
            this.selected = []
            this.load()
          })
        }
      })
    },

    downloadContacts () {
      this.downloading = true
      apiService.contactBook.download(this.realmName).finally(() => {
        this.downloading = false
      })
    }
  }

}
</script>
