import cacheControl from '@/helpers/cache.control'

import permissionHelper from '@/helpers/permission.helper'

export default function sipApiModule (instance) {
  return {

    /**
     * Get realms
     * @returns {Promise<*>}
     */
    getRealms: async () => {
      const timestamp = cacheControl.getTimestamp('sipAddress')
      let resource = `/sipaddresses/realms/user?time=${timestamp}`

      if (permissionHelper.can('has_access_to_all_realms')) {
        resource = `/sipaddresses/realms?time=${timestamp}`
      }

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Get user realms
     * @param userId User Id
     * @returns {Promise<*>}
     */
    getUserRealms: async (userId) => {
      const timestamp = cacheControl.getTimestamp('sipAddress')

      const resource = `/sipaddresses/realms/user?user_id=${userId}&${timestamp}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @param { number } userId
     *
     * @return { Promise } response
     */
    getAllSipAddresses: async userId => {
      const timestamp = cacheControl.getTimestamp('sipAddress')

      const resource = `/sipaddresses?user_id=${userId}&${timestamp}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @return { Promise } response
     */
    getDefaultSipAddresses: async () => {
      const timestamp = cacheControl.getTimestamp('sipAddress')

      const resource = `/sipaddresses/default?${timestamp}`

      const response = await instance.get(resource)

      return response.data
    },

    getSipAddressesByRealm: async realmName => {
      const resource = '/sipaddresses/by-realm'
      const response = await instance.get(resource, {
        params: {
          realm_name: realmName
        }
      })
      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    addSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses?user_id=${data.user_id}&${timestamp}`

      const response = await instance.post(resource, data)

      return response.data
    },

    updateDefaultSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/default/${data.id}?user_id=${data.user_id}&${timestamp}`

      const response = await instance.patch(resource, data)

      return response.data
    },

    /**
     * Update sip address
     * @param data
     * @returns {Promise<*>}
     */
    updateSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/${data.id}?user_id=${data.user_id}&${timestamp}`

      const response = await instance.patch(resource, data)

      return response.data
    },

    /**
     * Remove sip address
     * @param data
     * @returns {Promise<*>}
     */
    removeSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/${data.id}?user_id=${data.user_id}&${timestamp}`

      const response = await instance.delete(resource)

      return response.data
    },

    getRealmInfo: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/realm/info/${data.realm}?${timestamp}`

      const response = await instance.get(resource)

      return response.data
    }
  }
}
