export default function callLogDetailsApiModule (instance) {
  return {
    search: async (data) => {
      let resource = '/call-log-details/search?'

      for (const key in data) {
        resource += `${key}=${data[key]}&`
      }

      const response = await instance.get(resource)

      return response
    }

  }
}
