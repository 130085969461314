var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"1000","title":_vm.$t('bind_contact')},model:{value:(_vm.contactBookDialog),callback:function ($$v) {_vm.contactBookDialog=$$v},expression:"contactBookDialog"}},[_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('ContactBookComponent',{attrs:{"realmName":_vm.realmName},on:{"bindContact":_vm.bindContact}})],1)],1)],1),_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('folder'),"cypress":"switch_folder_contact"},model:{value:(_vm.form.is_folder),callback:function ($$v) {_vm.$set(_vm.form, "is_folder", $$v)},expression:"form.is_folder"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('name'),"name":"name","outlined":"","disabled":_vm.loading,"error-messages":errors,"cypress":"speed_dial_name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('parent_folder'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"clearable":"","label":_vm.$t('parent_folder'),"autocomplete":"false","item-text":"name","item-value":"id","cypress":"speed_dial_parent_id","disabled":_vm.loading,"error-messages":errors,"items":_vm.getItems,"required":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}}],null,true),model:{value:(_vm.form.parent_id),callback:function ($$v) {_vm.$set(_vm.form, "parent_id", $$v)},expression:"form.parent_id"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contact_permission'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.realmName)?_c('v-select',{attrs:{"error-messages":errors,"cypress":"speed_dial_permissions","items":[ {
                          value: 'private',
                          label: _vm.$t('private')
                        },
                        {
                          value: 'realm',
                          label: _vm.$t('realm') + ' ' + _vm.realmName
                        }],"outlined":"","name":"contact_permission","item-text":"label","item-value":"value","label":_vm.$t('contact_permission')},model:{value:(_vm.form.hot_contact_permission),callback:function ($$v) {_vm.$set(_vm.form, "hot_contact_permission", $$v)},expression:"form.hot_contact_permission"}}):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('icon'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":_vm.$t('icon'),"autocomplete":"false","item-text":"name","item-value":"name","cypress":"speed_dial_group","disabled":_vm.loading,"error-messages":errors,"items":_vm.getIcons,"required":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(data.item.name))]),_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"selection",fn:function(data){return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(data.item.name))]),_vm._v(" "+_vm._s(data.item.name)+" ")]}}],null,true),model:{value:(_vm.form.icon),callback:function ($$v) {_vm.$set(_vm.form, "icon", $$v)},expression:"form.icon"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('b',[_vm._v(_vm._s(_vm.$t('background_color')))]),(_vm.form.color)?_c('br'):_vm._e(),(_vm.form.color)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"red","small":""},on:{"click":_vm.onClearColorClick}},[_vm._v(_vm._s(_vm.$t('clear_color')))]):_vm._e(),_c('v-color-picker',{staticClass:"transparent-background full-width",attrs:{"noAlpha":true,"dark":_vm.$vuetify.theme.dark,"cypress":"speed_dial_colors","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","hide-sliders":"","swatches-max-height":"100","width":"100%","show-swatches":""},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}})],1)],1),(!_vm.form.is_folder)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(_vm.$t('contacts')))])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"icon":"","cypress":"speed_dial_set_contact"},on:{"click":_vm.addRow}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3136470027)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add_new')))])])],1)],1):_vm._e(),(!_vm.form.is_folder)?_c('v-row',_vm._l((_vm.contacts),function(contact,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contact'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"contact_name","disabled":_vm.loading,"outlined":"","error-messages":errors,"readonly":"","value":contact.name}},[_c('span',{staticClass:"pointer",attrs:{"slot":"append","cypress":"speed_dial_bind_contact"},on:{"click":function($event){return _vm.chooseContact(contact, index)}},slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-account ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bind_contact')))])])],1),( _vm.contacts.length > 1)?_c('div',{staticClass:"button-position",attrs:{"slot":"append-outer"},slot:"append-outer"},[_c('v-btn',{attrs:{"x-small":"","color":"error","disabled":_vm.loading,"fab":"","elevation":"0"},on:{"click":function($event){return _vm.deleteRow(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()])]}}],null,true)})],1)}),1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"passive_button","disabled":_vm.loading},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"type":"submit","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }