<template>

  <v-card tile flat color="secondary_card">
    <v-card-text>
      <validation-observer ref="observer">

        <v-form @submit.prevent="onSubmit" novalidate>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" name="name" rules="required" cypress="contact_group_name">
                <v-text-field outlined name="name" :label="$t('name')" v-model="form.name"
                              :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>

          </v-row>

          <v-row dense>
            <v-col>

              <validation-provider v-slot="{ errors }" :name="$t('icon')">
                <v-autocomplete :label="$t('icon')" clearable autocomplete="false" item-text="label" item-value="icon"
                                :disabled="loading"
                                :error-messages="errors" v-model="form.icon" :items="getIcons" required outlined
                                cypress="contact_group_type">
                  <template v-slot:item="data">
                    <v-icon class="mr-2"> {{ data.item.icon }}</v-icon>
                    {{ data.item.label }}
                  </template>

                  <template v-slot:selection="data">
                    <v-icon class="mr-2"> {{ data.item.icon }}</v-icon>
                    {{ data.item.label }}
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col >
              <v-btn v-if="form.color" class="mb-2" color="red" small @click="onClearColorClick">{{$t('clear_color')}}</v-btn>

              <v-color-picker :noAlpha="true" class="transparent-background full-width"
                              :dark="$vuetify.theme.dark" cypress="speed_dial_colors"
                              hide-canvas hide-inputs v-model="form.color"
                              hide-mode-switch hide-sliders swatches-max-height="100"
                              width="100%" show-swatches></v-color-picker>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col>
              <v-btn class="mr-5" color="passive_button" :disabled="loading" @click="onCancel()">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn :disabled="loading" type="submit" color="red">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-form>

      </validation-observer>

    </v-card-text>
  </v-card>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'ContactGroupFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  computed: {
    getIcons () {
      return [
        { icon: 'mdi-phone', label: 'mdi-phone' },
        { icon: 'mdi-account-group', label: 'mdi-account-group' },
        { icon: 'mdi-airplane', label: 'mdi-airplane' },
        { icon: 'mdi-ambulance', label: 'mdi-ambulance' },
        { icon: 'mdi-bank', label: 'mdi-bank' },
        { icon: 'mdi-fire', label: 'mdi-fire' },
        { icon: 'mdi-fireplace', label: 'mdi-fireplace' },
        { icon: 'mdi-hospital-building', label: 'mdi-hospital-building' },
        { icon: 'mdi-hospital', label: 'mdi-hospital' },
        { icon: 'mdi-police-badge', label: 'mdi-police-badge' },
        { icon: 'mdi-account-hard-hat', label: 'mdi-account-hard-hat' },
        { icon: 'mdi-account-supervisor', label: 'mdi-account-supervisor' }
      ]
    }
  },

  data: () => ({
    search: '',
    loading: false,

    colorPicker: {
      hex: null
    },

    form: {
      id: null,
      name: '',
      icon: null,
      colour: null
    }
  }),

  mounted () {
    if (this.entity) {
      this.form = Object.assign({}, this.entity)
      this.colorPicker.hex = this.form.colour
    } else {
      this.onClearColorClick()
    }
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    onClearColorClick () {
      this.form.colour = null
      this.colorPicker.hex = null
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.form.colour = this.colorPicker.hex

      this.loading = true

      if (this.form.id) {
        apiService.contactGroups.edit(this.entity.id, this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('updated')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      } else {
        apiService.contactGroups.create(this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('created')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
