<template>

  <v-card tile flat color="secondary_card">
    <v-card-text>
      <validation-observer ref="observer">

        <v-form @submit.prevent="onSubmit" novalidate>

          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="domain"
                :name="$t('domain')"
                :rules="{ required: true, 'proxy' : true }"
              >
                <v-text-field v-model="form.domain" :placeholder="$t('domain')" :error-messages="errors"/>
              </validation-provider>
            </v-col>

          </v-row>

          <v-row dense>
            <v-col>
              <v-btn class="mr-5" color="passive_button" :disabled="loading" @click="onCancel()">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn :disabled="loading" type="submit" color="red">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      </validation-observer>

    </v-card-text>
  </v-card>

</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'ProxyFormComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: () => ({
    search: '',
    loading: false,
    form: {
      id: null,
      domain: null
    }
  }),

  computed: {},

  mounted () {
    if (this.entity) {
      this.form = Object.assign({}, this.entity)
    }
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.loading = true

      if (this.form.id) {
        apiService.proxy.edit(this.entity.id, this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('updated')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      } else {
        apiService.proxy.create(this.form).then((response) => {
          this.loading = false

          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('created')
          })

          this.$emit('onSubmit')
        }).catch((error) => {
          this.loading = false
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        })
      }
    },

    onCancel () {
      this.$emit('onCancel')
    }

  }
}
</script>

<style scoped>

</style>
