var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"1200","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('LogoUploadForm',{key:_vm.formKey,on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('layout_settings'))+" ")]),_c('v-spacer'),(_vm.$can('can_change_admin_logo'))?_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":_vm.onCreate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-upload ")])]}}],null,false,3036686354)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('upload_logo')))])])],1):_vm._e()],1),_c('v-data-table',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"items":_vm.logos,"loading":_vm.loading,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('img',{attrs:{"src":item.file,"height":"90px"}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('can_change_admin_logo'))?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('delete')))])])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }