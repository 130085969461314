var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"pt-4",attrs:{"disabled":_vm.loading,"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"realm","name":_vm.$t('realm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"error-messages":errors,"name":"realm","label":_vm.$t('realm'),"items":_vm.realms,"dark":_vm.$vuetify.theme.dark,"outlined":"","autocomplete":"new-password","hint":_vm.$t('choose_realm'),"filter":_vm.filterRealms},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true),model:{value:(_vm.form.realm),callback:function ($$v) {_vm.$set(_vm.form, "realm", $$v)},expression:"form.realm"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"vid":"email_extension","name":_vm.$t('email_extension'),"rules":"required|emailExtension"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email_extension","outlined":"","label":_vm.$t('email_extension'),"error-messages":errors},model:{value:(_vm.form.emailExtension),callback:function ($$v) {_vm.$set(_vm.form, "emailExtension", $$v)},expression:"form.emailExtension"}})]}}])})],1),_c('v-col',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"vid":"password","name":_vm.$t('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password","outlined":"","label":_vm.$t('password'),"error-messages":errors},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"vid":"sip_type","name":_vm.$t('sip_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"sip_type","label":_vm.$t('sip_type'),"outlined":"","items":_vm.types,"error-messages":errors,"multiple":""},model:{value:(_vm.form.types),callback:function ($$v) {_vm.$set(_vm.form, "types", $$v)},expression:"form.types"}})]}}])})],1),_c('v-col',{staticClass:"col-6"},[_c('validation-provider',{attrs:{"vid":"sip_password","name":_vm.$t('sip_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"sip_password","outlined":"","label":_vm.$t('sip_password'),"error-messages":errors},model:{value:(_vm.form.sipPassword),callback:function ($$v) {_vm.$set(_vm.form, "sipPassword", $$v)},expression:"form.sipPassword"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"items":_vm.form.devices,"loading":_vm.loading,"options":_vm.options,"headers":_vm.headers,"show-select":"","hide-default-footer":"","disable-pagination":"","search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"name":"search","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.username) + "@" + (_vm.form.emailExtension)))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-col',[_c('v-select',{attrs:{"value":4,"outlined":"","items":_vm.roles,"hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true),model:{value:(_vm.form.roles[item.id]),callback:function ($$v) {_vm.$set(_vm.form.roles, item.id, $$v)},expression:"form.roles[item.id]"}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"passive_button","disabled":_vm.loading},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"type":"submit","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }