export default function proxyApiModule (instance) {
  return {

    /**
     * Return list of proxy domains
     * @param data
     * @returns {Promise<*>}
     */
    load: async (data) => {
      const resource = '/proxy'

      const response = await instance.get(resource, data)

      return response
    },

    getProxyData: async (url) => {
      const resource = `/proxy/url?endpoint_key=${url}`
      return await instance.get(resource, {
        headers: {
          accept: 'application/xml'
        }
      })
    },

    /**
     * Get single proxy domain
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    get: async (id) => {
      const resource = '/proxy/' + id

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create proxy domain
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: async (data) => {
      const resource = '/proxy'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Update proxy domain
     *
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: async (id, data) => {
      const resource = `/proxy/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete proxy domain
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: async (id) => {
      const resource = `/proxy/${id}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
