var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("v-treeview-node v-treeview-node--click " + (_vm.hasChildren ? '' : 'v-treeview-node--leaf'))},[_c('div',{staticClass:"v-treeview-node__root",on:{"click":function($event){_vm.open = !_vm.open}}},[_vm._l((_vm.appendLevel),function(index){return _c('div',{key:index,staticClass:"v-treeview-node__level"})}),(_vm.hasChildren)?_c('i',{staticClass:"v-icon notranslate v-treeview-node__toggle v-icon--link mdi",class:[
        {
          'v-treeview-node__toggle--open': _vm.open,
          'theme--dark': _vm.isDark,
          'theme--light': !_vm.isDark,
        },
        _vm.expandIcon ],attrs:{"role":"button"}}):_vm._e(),_vm._t("prepend",null,null,{ item: _vm.value, open: _vm.open }),_c('div',{staticClass:"v-treeview-node__label"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.value.name)+" ")]},null,{ item: _vm.value, open: _vm.open })],2),_vm._t("append",null,null,{ item: _vm.value })],2),(_vm.open)?_c('div',{staticClass:"v-treeview-node__children v-treeview-node__children__draggable"},[_c('draggable',{attrs:{"group":_vm.group,"value":_vm.value.children,"ghost-class":"ghost"},on:{"input":_vm.updateValue}},_vm._l((_vm.value.children),function(child){return _c('treeview-node',{key:child.id,attrs:{"group":_vm.group,"value":child,"level":_vm.level + 1,"expand-icon":_vm.expandIcon},on:{"input":_vm.updateChildValue,"nodeChange":_vm.nodeChange},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
      var item = ref.item;
      var open = ref.open;
return [_vm._t("prepend",null,null,{ item: item, open: open })]}},{key:"label",fn:function(ref){
      var item = ref.item;
      var open = ref.open;
return [_vm._t("label",null,null,{ item: item, open: open })]}},{key:"append",fn:function(ref){
      var item = ref.item;
return [_vm._t("append",null,null,{ item: item })]}}],null,true)})}),1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }