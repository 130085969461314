<template>
  <div id="test" style="white-space: break-spaces; line-break: anywhere; font-size: 18px;" v-html="logdata"></div>
</template>
<script>
export default {
  name: 'LogViewerViewComponents',
  props: ['entity', 'value'],
  data: () => ({
    logdata: null,
    htmlEscape: {
      '&': '&#38;',
      '"': '&#34;',
      "'": '&#39;',
      '<': '&#60;',
      '>': '&#62;'
    }
  }),
  methods: {
    parse () {
      const tmp = { ...this.entity }

      if (!tmp.method) tmp.method = ''
      if (!tmp.message) tmp.message = ''

      tmp.message = tmp.message.replaceAll(/\n/ig, '\n')

      tmp.message = '\n' + tmp.message

      for (const entry in this.htmlEscape) {
        tmp.message = tmp.message.replaceAll(entry, this.htmlEscape[entry])
      }

      this.logdata = tmp

      this.parseMessage()
    },
    parseMessage () {
      this.logdata.message = this.logdata.message.replaceAll(/\r/ig, '')
      this.logdata.message = this.logdata.message.replaceAll(/\n/ig, '&#10;')

      if (this.logdata.type.toLowerCase() === 'text') this.logdata.message = this.beautifyMessage(this.logdata.message)

      if (this.logdata.method === 'CAD') this.logdata.message = JSON.parse(this.logdata.message)

      if (['adr', 'held'].indexOf(this.logdata.method.toLowerCase()) !== -1) {
        this.logdata.response = this.formatXml(this.logdata.response)

        this.logdata.response = this.logdata.response.replaceAll(/\r/ig, '')
        this.logdata.response = this.logdata.response.replaceAll(/\n/ig, '&#10;')

        this.logdata.response = `<code>${this.logdata.response}</code>`
      }

      if (this.logdata.type.toLowerCase() === 'json') {
        this.logdata.message = `<code>${this.logdata.message.trim()}</code>`

        for (const entry in this.htmlEscape) {
          this.logdata.response = this.logdata.response.replaceAll(entry, this.htmlEscape[entry])
        }

        this.logdata.response = `<code>${this.logdata.response.trim()}</code>`
      }
    },
    formatXml (xml) {
      const PADDING = ' '.repeat(2) // set desired indent size here
      const reg = /(>)(<)(\/*)/g
      let pad = 0

      xml = xml.replace(reg, '$1\r\n$2$3')

      const test = xml.split('\r\n').map((node, index) => {
        let indent = 0
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0
        } else if (node.match(/^<\/\w/) && pad > 0) {
          pad -= 1
        } else if (node.match(/^<\w[^>]*[^\\/]>.*$/)) {
          indent = 1
        } else {
          indent = 0
        }

        pad += indent

        return PADDING.repeat(pad - indent) + node
      }).join('\r\n')

      const xmlEscaped = test.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/ /g, '&nbsp;').replace(/\n/g, '<br />')

      return xmlEscaped
    },
    beautifyMessage (message) {
      let lines = message.split('&#10;')

      lines = lines.map(line => {
        line = line.split(' ')

        line[0] = `<strong>${line[0]}</strong>`

        line = line.join(' ')

        return line
      })

      return lines.join('&#10;')
    }
  },
  watch: {
    entity () {
      this.parse()
    }
  },
  mounted () {
    this.parse()
  }
}
</script>
