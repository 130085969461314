import cacheControl from '@/helpers/cache.control'

export default function usersApiModule (instance) {
  return {

    /**
     * Search user
     * @param data
     * @returns {Promise<*>}
     */
    search: async (data) => {
      const timestamp = cacheControl.getTimestamp('users')

      const resource = '/users/search/' + data.search + `?${timestamp}`

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Return list of users
     *
     * @param data
     * @returns {Promise<*>}
     */
    list: async (data) => {
      const timestamp = cacheControl.getTimestamp('users')

      const resource = `/users?${timestamp}`

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Get single user
     *
     * @param id
     * @returns {Promise<*>}
     */
    get: async (id) => {
      const resource = '/users/' + id

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create User
     *
     * @param data
     * @returns {Promise<*>}
     */
    create: async (data) => {
      const timestamp = cacheControl.getTimestamp('users', 'replace')

      const resource = `/users?${timestamp}`

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Delete User
     *
     * @param id
     * @returns {Promise<*>}
     */
    delete: async (id) => {
      const timestamp = cacheControl.getTimestamp('users', 'replace')

      const resource = `/users/${id}?${timestamp}`

      const response = await instance.delete(resource)

      return response
    },

    /**
     * Update user
     *
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const timestamp = cacheControl.getTimestamp('users', 'replace')

      const resource = `/users/${id}?${timestamp}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Import Users
     *
     * @param data
     * @returns {Promise<*>}
     */
    importUsers: async (data) => {
      const timestamp = cacheControl.getTimestamp('users', 'replace')

      const resource = `/users/create-by-file?${timestamp}`

      const formData = new FormData()
      formData.append('file', data.file)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response
    },

    /**
     * Import Users
     *
     * @param data
     * @returns {Promise<*>}
     */
    importUsersFromIPPBX: async (data) => {
      const resource = '/users/create-by-ippbx'

      const response = await instance.post(resource, data)

      return response
    }

  }
}
