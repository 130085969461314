<template>
    <div >

      <DialogShared max-width="1000" v-model="dialog" :title="dialogTitle" >
        <RingtoneForm :key="formKey"  @onCancel="dialog = false" @onSubmit="onFormSubmit" />
      </DialogShared>

      <div class="d-flex align-center mb-2">

        <h2 >
          {{ $t('global_settings')}}
        </h2>

        <v-spacer />

        <v-btn class="ml-auto" @click="onCreate" icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">
                mdi-plus
              </v-icon>
            </template>
            <span> {{ $t('upload') }}</span>
          </v-tooltip>
        </v-btn>

      </div>

      <v-alert
        dense
        type="info"
      >
        {{ $t('changes_will_be_applied_after_relogin_or_page_refresh')}}
      </v-alert>

      <v-btn class="mr-1" x-small @click="switchFilter(false)"> {{ $t('only_active')}}</v-btn>
      <v-btn x-small @click="switchFilter(true)"> {{ $t('all')}}</v-btn>

      <v-data-table class="transparent-background mt-2" :dark="$vuetify.theme.dark"
                    :items="list"
                    :loading="loading"
                    :headers="headers"
                    hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="mr-1" color="green" small @click.stop="onMarkAsDefault(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-check
                </v-icon>
              </template>
              <span> {{ $t('make_default')}}</span>
            </v-tooltip>
          </v-btn>

          <v-btn class="mr-1" color="error" small @click.stop="onDeleteRingtone(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-delete-outline
                </v-icon>
              </template>
              <span> {{ $t('delete')}}</span>
            </v-tooltip>
          </v-btn>

        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type }}
        </template>
        <template v-slot:[`item.is_default`]="{ item }">
          <span v-if="item.is_default">
            Active
          </span>
        </template>
        <template v-slot:[`item.file_url`]="{ item }">

          <span style="font-size: 10px">
             {{ item.file_name}}
          </span>

          <audio-player :key="formKey+'_'+item.file_id" :flat="true" :auto-play="false" :file="item.file_url" ></audio-player>

        </template>
      </v-data-table>
    </div>

</template>

<script>

import apiService from '@/modules/api/csp'
import { mapActions } from 'vuex'

import RingtoneForm from '@/app/widgets/global-ringtones-widget/components/UploadGlobalRingtoneFormComponent'
import AudioPlayer from '@/app/shared/AudioComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {
  name: 'GlobalRingtoneSettingsTab',
  components: {
    DialogShared,
    RingtoneForm,
    AudioPlayer
  },
  data: (vm) => ({
    showAll: false,
    sound: null,
    list: [],
    entity: null,
    dialog: false,
    formKey: 0,
    dialogTitle: vm.$t('create_ringtone'),

    loading: false,
    headers: [

      { text: vm.$t('file'), value: 'file_url', sortable: false },
      { text: vm.$t('active'), value: 'is_default', sortable: false },
      { text: vm.$t('type'), value: 'type', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]
  }),
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    onCreate () {
      this.entity = null
      this.refreshForm()
      this.dialog = true
    },
    refreshForm () {
      this.formKey++
    },
    onFormSubmit () {
      this.dialog = false

      this.loadList()
    },
    onMarkAsDefault (item) {
      this.loading = true

      apiService.globalRingtone.markAsDefault(item.file_id).then(response => {
        this.loadList()
      })
    },

    onDeleteRingtone (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('ringtone') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          apiService.globalRingtone.deleteRingtone(item.file_id).then(response => {
            this.loadList()
          })
        }
      })
    },

    playSound (item) {
      this.sound = new Audio(item.file_url)
      this.sound.play()
    },
    stopSound () {
      this.sound.pause()
      this.sound.currentTime = 0
    },

    switchFilter (all = false) {
      this.showAll = all
      this.loadList()
    },

    loadList () {
      this.loading = true
      this.refreshForm()

      if (this.showAll) {
        apiService.globalRingtone.getAll().then(response => {
          this.list = response.data
        }).finally(() => {
          this.loading = false
        })
      } else {
        apiService.globalRingtone.getAllDefault().then(response => {
          this.list = response.data
        }).finally(() => {
          this.loading = false
        })
      }
    }
  },
  mounted () {
    this.loadList()
  }
}
</script>
