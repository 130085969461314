var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"900","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('MapLayersSettingsForm',{key:_vm.formKey,attrs:{"isEditModalMode":_vm.isEditModalMode,"entity":_vm.entity},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit,"addToCurrentList":_vm.addToCurrentList,"updateInCurrentList":_vm.updateInCurrentList}})],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(_vm._s(_vm.$t('map_layers_settings')))]),_c('v-spacer'),_c('v-select',{staticClass:"mt-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.realms,"label":_vm.$t('realm')},on:{"change":_vm.fetchMapLayersHandler},model:{value:(_vm.realmName),callback:function ($$v) {_vm.realmName=$$v},expression:"realmName"}})],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onCreateModalOpen}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('add')))])])],1)],1),_c('v-alert',{attrs:{"type":"info","color":"red lighten-2","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('changes_for_account'))+" "),_c('b',[_vm._v(_vm._s(_vm.realmName))])]),_c('v-data-table',{staticClass:"transparent-background",attrs:{"disable-pagination":true,"hide-default-footer":true,"items":_vm.mapLayersList,"loading":_vm.loading,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{staticClass:"tooltip",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncateURL(item.url))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.url))])])]}},{key:"item.refresh_rate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(((item.refresh_rate) + " " + (_vm.minutesPrefixPluralForm(item.refresh_rate))))+" ")])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":_vm.loading},on:{"change":function($event){return _vm.onLayerToggle(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditModalOpen(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.loading,"color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }