export default function appsApiModule (instance) {
  return {

    /**
     * Return list of apps
     * @param data
     * @returns {Promise<*>}
     */
    list: async (data) => {
      const resource = '/apps'

      const response = await instance.get(resource, data)

      return response
    }

  }
}
