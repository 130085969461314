export default function chatTextLinesApiModule (instance) {
  return {
    load: async (realm) => {
      const resource = `/chat-text-lines/${realm}`

      const response = await instance.get(resource)

      return response
    },

    get: async (id) => {
      const resource = `/chat-text-lines/${id}`

      const response = await instance.get(resource)

      return response
    },

    create: async (data) => {
      const resource = '/chat-text-lines'

      const response = await instance.post(resource, data)

      return response
    },

    edit: async (id, data) => {
      const resource = `/chat-text-lines/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    delete: async (id) => {
      const resource = `/chat-text-lines/${id}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
