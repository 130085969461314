<template>
  <div>

    <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
      <CallLinesForm :key="formKey" :realmName="realmName" :entity="entity" @onCancel="dialog = false" @onSubmit="onFormSubmit" />
    </DialogShared>

    <v-select v-if="$can('has_access_to_all_realms')" @change="loadCallLines"
              :label="$t('realm')"
              v-model="realmName"
              :items="realms"
    ></v-select>

    <v-alert type="info"

             color="red lighten-2"
             dark
    >
      Changes for account <b>{{ realmNameReadOnly }}</b>
    </v-alert>

    <v-alert
      dense
      type="info"
    >
      {{ $t('changes_will_be_applied_after_relogin_or_page_refresh')}}
    </v-alert>

    <div v-if="$can('can_create_call_lines')" class="text-right">
      <v-btn @click="onCreate" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add_call_line')}}</span>
        </v-tooltip>
      </v-btn>
    </div>

    <v-data-table :disable-pagination="true" :hide-default-footer="true"  class="transparent-background"
                  :items="callLines"
                  :loading="loading"

                  :headers="headers"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-btn v-if="$can('can_update_call_lines')" :disabled="loading" class="mr-1" color="green" small @click.stop="onEdit(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit')}}</span>
          </v-tooltip>
        </v-btn>

        <v-btn v-if="$can('can_delete_call_lines')" :disabled="loading" class="mr-1" color="red" small @click.stop="onDelete(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete')}}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions, mapGetters } from 'vuex'
import CallLinesForm from '@/app/widgets/call-line-settings-widget/components/CallLinesFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

export default {

  name: 'RealmSettingsView',

  components: { CallLinesForm, DialogShared },
  data: (vm) => ({

    entity: {},
    formKey: 0,
    dialogTitle: vm.$t('update_realm_setting'),
    dialog: false,
    callLines: [],

    realmName: '',
    realmNameReadOnly: '',
    realms: [],
    loading: false,

    headers: [
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('call_lines_header_key'), value: 'key', sortable: false },
      { text: vm.$t('call_lines_header_value'), value: 'value', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  async mounted () {
    await this.loadRealms()

    if (this.realms.length > 0) {
      this.realmName = this.realms[0]
    }

    await this.loadCallLines()
  },

  computed: {

    ...mapGetters('settings', [
      'sipDefaultAddressGetter'
    ])

  },

  methods: {
    ...mapActions('settings', [
      'sipAddressesAction',
      'sipDefaultAddressAction'
    ]),
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    refreshForm () {
      this.formKey += 1
    },

    async loadRealms () {
      this.loading = true
      await apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async loadCallLines () {
      this.loading = true

      if (!this.realmName) return

      // when admin changes realm - reload read only info
      this.realmNameReadOnly = this.realmName

      await apiService.callLines.load(this.realmName).then((result) => {
        this.callLines = result.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async onItemChange (item) {
      apiService.realmSettings.edit(item.id, item, this.realmName).then((response) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: this.$t('realm_setting_updated')
        })
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    onFormSubmit () {
      this.dialog = false
      this.loadCallLines()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('call_lines_update')
      this.refreshForm()
      this.entity = item
      this.dialog = true
    },

    onCreate () {
      this.dialogTitle = this.$t('add_call_line')

      this.refreshForm()
      this.dialog = true
    },

    onDelete (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('call_line') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          apiService.callLines.delete(item.id).then(response => {
            this.loadCallLines()
          })
        }
      })
    }
  }

}
</script>
