<template>
  <div>

    <v-data-table v-if="realmName"  class="transparent-background"
                  :items="items"
                  :loading="loading"
                   v-model="selected"  show-expand
                  single-expand :expanded="expanded"
                  :headers="headers">

      <template v-slot:[`item.contact_permission`]="{ item }">
        <v-btn icon small v-if="item.contact_permission === 'private'">
          <v-icon small color="error">mdi-lock-open-remove-outline</v-icon>
        </v-btn>
        <v-btn icon small v-if="item.contact_permission === 'public'">
          <v-icon small color="success">mdi-lock-open-check-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.name`]="{ item }">

        <v-btn color="grey" small depressed min-width="0" width="30" height="30" class="mx-1 my-1">
          <v-icon class="grey" small>
            mdi-account-box-outline
          </v-icon>
        </v-btn>

        <v-tooltip bottom v-if="item.phone">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ item.phone.name  }}</span>
          </template>
          <span>{{ item.phone.name }}</span>
        </v-tooltip>

      </template>

      <template v-slot:[`item.phone`]="{ item }">

        <v-tooltip bottom v-if="item.phone">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ item.phone.phone  }}</span>
          </template>
          <span>{{ item.phone.phone }}</span>
        </v-tooltip>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   color="primary"
                   small
                   min-width="0"
                   width="30"
                   height="30"
                   class="mx-1 my-1"
                   @click.stop="bind(item)"
            >
              <v-icon color="white" small>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('bind_contact') }}</span>
        </v-tooltip>

      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="6" class="pa-0">
          <v-simple-table fixed-header class="expanded-table">
            <tbody>
            <template v-for="phones in item.contact_book_phones">
              <tr class="pa-0" v-if="!phones.is_primary" :key="phones.id">
                <td>
                  {{ $t('name')}}:
                  <span :title="phones.name">
                    {{ phones.name  }}
                  </span>
                </td>
                <td>
                  {{ $t('phone')}}:
                  <span :title="phones.phone">
                    {{ phones.phone  }}
                  </span>
                </td>

              </tr>
            </template>
            </tbody>
          </v-simple-table>
        </td>
      </template>

    </v-data-table>

  </div>
</template>

<script>

import apiService from '@/modules/api/csp'

import { mapActions } from 'vuex'

export default {

  name: 'ContactBookComponent',

  props: ['realmName'],

  components: { },
  data: (vm) => ({

    expanded: [],
    entity: {},
    formKey: 0,
    dialogTitle: vm.$t('create'),
    dialog: false,

    importDialog: false,

    items: [],

    loading: false,

    selected: [],

    headers: [
      { text: vm.$t('name'), value: 'name', sortable: true },
      { text: vm.$t('phone'), value: 'phone', sortable: false },
      { text: vm.$t('type'), value: 'contact_permission', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ]

  }),

  computed: {},

  mounted () {
    this.load()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async bind (item) {
      this.$emit('bindContact', item)
    },

    async load () {
      this.loading = true

      await apiService.contactBook.get({
        realm_name: this.realmName
      }).then((result) => {
        this.items = result.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    }

  }

}
</script>
