var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"900","scrollable":"","title":_vm.dialogTitle},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ContactGroupForm',{key:_vm.formKey,attrs:{"entity":_vm.entity},on:{"onCancel":function($event){_vm.dialog = false},"onSubmit":_vm.onFormSubmit}})],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('contact_groups'))+" ")]),_c('v-spacer'),(_vm.$can('can_create_contact_book_groups'))?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onCreate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,false,1287216139)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add')))])])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"transparent-background",attrs:{"disable-pagination":true,"hide-default-footer":true,"items":_vm.items,"loading":_vm.loading,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('can_update_contact_book_groups'))?_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])],1):_vm._e(),(_vm.$can('can_delete_contact_book_groups'))?_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.loading,"color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"smalls":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }