export default function logsApiModule (instance) {
  return {
    getByDate: async (query) => {
      const resource = '/logger/get-by-date'

      const response = await instance.post(resource, { query })

      return response
    }
  }
}
