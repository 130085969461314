<template>
  <div>

    <DialogShared v-model="dialog" max-width="900" scrollable :title="dialogTitle">
      <SpeedDialFormComponent :items="items"  :parentId="parentId" :realmName="realmName" :key="formKey" :entity="entity"
                              @onCancel="dialog = false" @onSubmit="onFormSubmit"/>
    </DialogShared>

    <div class="d-flex align-center mb-2">

      <h2>
        {{ $t('speed_dial') }} {{ realmName }}
      </h2>

      <v-spacer/>

      <v-select style="max-width: 300px" class="mt-2" @change="loadTree()"
                :label="$t('realm')"
                v-model="realmName"
                :items="realms"
      ></v-select>

      <div class="text-right" v-if="realmName">
        <v-btn @click="addToRootClick" icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" v-bind="attrs" v-on="on">
                mdi-plus
              </v-icon>
            </template>
            <span> {{ $t('add_to_root') }}</span>
          </v-tooltip>
        </v-btn>
      </div>

    </div>

    <div v-if="realmName">

      <div class="text-right" v-if="$canAny('can_delete_speed_dial')" >
        <v-btn :disabled="loading" class="mr-1 " color="red" small @click.stop="onDeleteAll()">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">

              <v-icon smalls v-bind="attrs" v-on="on">
                mdi-delete
              </v-icon>

              {{ $t('delete_all_speed_dials') }}
            </template>
            <span>{{ $t('delete_all_speed_dials') }}</span>
          </v-tooltip>
        </v-btn>
      </div>

      <draggable-treeview v-model="itemsTree" @nodeChange="saveOrder" @updateTree="saveOrder">
        <template v-slot:prepend="{ item }">

          <v-icon :style="'color: '+ item.data.color " class="mr-2">{{ item.data.icon }}</v-icon>
        </template>
        <template v-slot:label="{ item }">

          <div class="d-flex d-inline-flex align-center">
            <span class="primary--text mr-4 ml-4">
              {{ item.data.name}}

            </span>
            <span class="mr-4">
              ({{ item.data.hot_contact_permission }}) ({{ item.data.order_by }})
            </span>

            <v-avatar size="25" color="success" @click.stop="onEdit(item.data)">
              <v-icon small dark>
                mdi-pencil
              </v-icon>
            </v-avatar>

            <v-avatar class="ml-2" size="25" v-if="$canAny('can_delete_speed_dial')" color="error" @click.stop="onDeleteClick(item)">
              <v-icon small dark>
                mdi-delete
              </v-icon>
            </v-avatar>

            <v-avatar v-if="item.data.is_folder" class="ml-2" size="25" color="info" @click.stop="onCreate(item.data)">
              <v-icon small dark>
                mdi-plus
              </v-icon>
            </v-avatar>
          </div>

        </template>

      </draggable-treeview>

    </div>
  </div>
</template>

<script>

import apiService from '@/modules/api/csp'
import { mapActions } from 'vuex'
import SpeedDialFormComponent from '@/app/widgets/speed-dial-widget/components/SpeedDialFormComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'
import DraggableTreeview from '@/app/widgets/speed-dial-widget/components/draggable-treeview/DraggableTreeview'

export default {
  name: 'SpeedDialWidget',
  components: { DraggableTreeview, SpeedDialFormComponent, DialogShared },
  data: (vm) => ({
    itemsTree: [],
    items: [],

    realms: [],
    realmName: '',
    loading: false,

    entity: {},
    formKey: 0,
    dialogTitle: vm.$t('create'),
    dialog: false,
    parentId: null

  }),

  async mounted () {
    await this.loadRealms()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onDeleteAll () {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('all_speed_dials') })).then(async confirmed => {
        if (confirmed) {
          this.loading = true
          await apiService.speedDial.deleteAll(this.realmName).then((result) => {
            this.loading = false
            this.loadTree()
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('deleted')
            })
          }).catch(() => {
            this.loading = false
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: this.$t('something_went_wrong_please_try_again_later')
            })
          })
        }
      })
    },

    async loadRealms () {
      this.loading = true
      await apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    refreshForm () {
      this.formKey += 1
    },

    onFormSubmit () {
      this.dialog = false
      this.loadTree()
    },

    onEdit (item) {
      this.dialogTitle = this.$t('update')
      this.refreshForm()
      this.entity = item
      this.dialog = true
    },

    onCreate (item) {
      this.parentId = item ? item.id : null

      this.entity = null
      this.dialogTitle = this.$t('add')

      this.refreshForm()
      this.dialog = true
    },

    onDeleteClick (item) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('speed_dial') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          apiService.speedDial.delete(item.data.id).then(() => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('deleted')
            })

            this.loadTree()
          }).catch((data) => {
            this.loading = false

            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: data.message
            })
          })
        }
      })
    },

    addToRootClick () {
      this.onCreate()
    },

    async saveOrder (array) {
      await array.forEach((element, index, array) => {
        element.data.order_by = index + 1
        apiService.speedDial.edit(element.data.id, element.data)
      })

      this.notificationSnackBarAction({
        show: true,
        color: 'success',
        text: this.$t('order_updated')
      })

      await this.loadTree()
    },

    async loadTree () {
      this.loading = true

      await apiService.speedDial.tree({
        realm_name: this.realmName
      }).then(async (result) => {
        this.itemsTree = result.data

        await this.load()

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },

    async load () {
      this.loading = true

      await apiService.speedDial.get({
        realm_name: this.realmName
      }).then((result) => {
        this.items = result.data

        this.loading = false
      }).catch((e) => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    }
  }

}
</script>

<style scoped>

</style>
