var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"pt-4",attrs:{"autocomplete":"off","novalidate":"","disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"name","label":_vm.$t('name'),"error-messages":errors},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"password","name":_vm.$t('password'),"rules":{ required : !_vm.form.id}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","outlined":"","name":"password","autocomplete":"new-password","label":_vm.$t('password'),"error-messages":errors},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"email","name":_vm.$t('email'),"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"email","autocomplete":"new-email","label":_vm.$t('email'),"error-messages":errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"roles","name":_vm.$t('roles'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","onabort":"roles","items":_vm.roles,"label":_vm.$t('roles'),"item-value":"name","item-text":"name","multiple":"","small-chips":"","persistent-hint":"","error-messages":errors},model:{value:(_vm.form.roles),callback:function ($$v) {_vm.$set(_vm.form, "roles", $$v)},expression:"form.roles"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"direct_permissions","name":_vm.$t('permissions'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"clearable":"","outlined":"","onabort":"permissions","items":_vm.permissions,"label":_vm.$t('permissions'),"item-value":"name","item-text":"name","multiple":"","small-chips":"","persistent-hint":"","error-messages":errors},model:{value:(_vm.form.direct_permissions),callback:function ($$v) {_vm.$set(_vm.form, "direct_permissions", $$v)},expression:"form.direct_permissions"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"mobile_phone","name":_vm.$t('mobile_phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"mobile_phone","label":_vm.$t('mobile_phone'),"error-messages":errors},model:{value:(_vm.form.mobile_phone),callback:function ($$v) {_vm.$set(_vm.form, "mobile_phone", $$v)},expression:"form.mobile_phone"}})]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"passive_button","disabled":_vm.loading},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"type":"submit","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }