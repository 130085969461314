<template>

  <validation-observer ref="observer" >

    <v-form class="pt-4" :disabled="loading" @submit.prevent="onSubmit" novalidate >

      <v-row dense>
        <v-col>
          <validation-provider vid="realm" :name="$t('realm')" rules="required" v-slot="{ errors }">
            <v-combobox
              :error-messages="errors"
              name="realm"
              :label="$t('realm')"
              :items="realms"
              :dark="$vuetify.theme.dark"
              outlined
              autocomplete="new-password"
              :hint="$t('choose_realm')"
              v-model="form.realm"
              :filter="filterRealms"
            >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">{{ item.name }}</template>
            </v-combobox>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col-6">
          <validation-provider vid="email_extension" :name="$t('email_extension')" rules="required|emailExtension" v-slot="{ errors }">
            <v-text-field
              name="email_extension"
              outlined
              :label="$t('email_extension')"
              v-model="form.emailExtension"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col class="col-6">
          <validation-provider vid="password" :name="$t('password')" rules="required" v-slot="{ errors }">
            <v-text-field
              name="password"
              outlined
              :label="$t('password')"
              v-model="form.password"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">
          <validation-provider vid="sip_type" :name="$t('sip_type')" rules="required" v-slot="{ errors }">
            <v-select
              name="sip_type"
              :label="$t('sip_type')"
              outlined
              :items="types"
              v-model="form.types"
              :error-messages="errors"
              multiple
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col class="col-6">
          <validation-provider vid="sip_password" :name="$t('sip_password')" rules="required" v-slot="{ errors }">
            <v-text-field
              name="sip_password"
              outlined
              :label="$t('sip_password')"
              v-model="form.sipPassword"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            class="transparent-background"
            :dark="$vuetify.theme.dark"
            :items="form.devices"
            :loading="loading"
            :options.sync="options"
            :headers="headers"
            show-select
            v-model="selected"
            hide-default-footer
            disable-pagination
            :search="search"
          >
            <template v-slot:top>
              <v-text-field
                name="search"
                :label="$t('search')"
                v-model="search"
              ></v-text-field>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              {{ `${item.username}@${form.emailExtension}` }}
            </template>
            <template v-slot:[`item.role`]="{ item }">
              <v-col>
                <v-select
                  :value="4"
                  outlined
                  :items="roles"
                  v-model="form.roles[item.id]"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">{{ item.name }}</template>
                </v-select>
              </v-col>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn :disabled="loading" type="submit" color="red">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

  </validation-observer>

</template>

<script>
import apiService from '@/modules/api/csp'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'

export default {

  name: 'ImportUsersFormIPPBXComponent',

  props: ['entity'],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data: (vm) => ({
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 10
    },
    search: '',
    headers: [
      { text: vm.$t('name'), value: 'name', sortable: false },
      { text: vm.$t('email'), value: 'email', sortable: false },
      { text: vm.$t('sip_address'), value: 'sip_address', sortable: false },
      { text: vm.$t('display_name'), value: 'sip_address_display_name', sortable: false },
      { text: vm.$t('account_name'), value: 'sip_address_account_name', sortable: false },
      { text: vm.$t('role'), value: 'role', sortable: false }
    ],
    types: ['softphone', 'sip_device'],
    roles: [],
    selected: [],
    defaultRole: {},
    form: {
      types: ['softphone'],
      roles: {},
      realm: {},
      devices: [],
      password: '',
      sipPassword: '',
      emailExtension: ''
    },
    realms: []
  }),

  computed: {
    ...mapGetters('settings', [
      'accountsGetter'
    ])
  },

  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return
      if (!this.selected.length) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('please_select_user')
        })

        return
      }

      this.loading = true

      await apiService.users
        .importUsersFromIPPBX(
          this.generateSendData(this.selected)
        ).then((response) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('users_imported_successfully')
          })

          this.onCancel()
          this.$emit('onSubmit')
        }).catch((error) => {
          if (error.response.data.message) {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message[0]
            })
          }

          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        }).finally(() => {
          this.loading = false
        })
    },

    onCancel () {
      this.$refs.observer.reset()
      this.$emit('onCancel')
    },

    loadDevices (id) {
      this.form.types.forEach(type => {
        this.loading = true

        apiService.egress.getDevicesList(id, {
          filter_device_type: type,
          page_size: 1000
        }).then((response) => {
          this.form.devices = [...this.form.devices, ...response.data.data.map(item => {
            item.email = `${item.username}@${this.form.emailExtension}`
            item.sip_address = `${item.username}@${this.form.realm.realm}`
            item.sip_address_realm = this.form.realm.realm
            item.sip_address_account_id = this.form.realm.id
            item.sip_address_display_name = item.name
            item.sip_address_account_name = item.name

            this.form.roles[item.id] = this.defaultRole

            return item
          })]
        }).finally(() => {
          this.loading = false
        })
      })
    },

    filterRealms (item, search) {
      return item.name.toLowerCase().indexOf(search.toLowerCase()) === 0
    },

    generateSendData (items) {
      return items.map(item => ({
        ...item,
        // Let it be so, in case, in future we will need more than one permissions
        email: `${item.username}@${this.form.emailExtension}`,
        roles: [this.form.roles[item.id].name].join(','),
        password: this.form.password,
        sip_address_password: this.form.sipPassword
      }))
    },

    reloadDevices () {
      // We need to clear old devices list
      this.selected = []
      this.form.devices = []

      if (!this.form.realm || !this.form.realm.id) return

      this.loadDevices(this.form.realm.id)
    }
  },

  watch: {
    'form.realm' () {
      this.reloadDevices()
    },

    'form.types' () {
      this.reloadDevices()
    }
  },

  mounted () {
    apiService.roles.list().then((result) => {
      this.roles = result.data

      this.defaultRole = this.roles[3]
    })

    this.realms = this.accountsGetter
  }
}
</script>
