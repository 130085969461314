import state from '@/store/settings/state'
import * as actions from '@/store/settings/actions'
import * as getters from '@/store/settings/getters'
import * as mutations from '@/store/settings/mutations'

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
