
export default () => ({

  settingsProxyState: [],
  addresses: [],
  sipDefaultAddress: null,

  accounts: [],
  accountNames: [],

  userSettings: [],
  globalSettings: []
})
