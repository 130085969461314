var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.realmName)?_c('v-data-table',{staticClass:"transparent-background",attrs:{"items":_vm.items,"loading":_vm.loading,"show-expand":"","single-expand":"","expanded":_vm.expanded,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.contact_permission",fn:function(ref){
var item = ref.item;
return [(item.contact_permission === 'private')?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-lock-open-remove-outline")])],1):_vm._e(),(item.contact_permission === 'public')?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-lock-open-check-outline")])],1):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1 my-1",attrs:{"color":"grey","small":"","depressed":"","min-width":"0","width":"30","height":"30"}},[_c('v-icon',{staticClass:"grey",attrs:{"small":""}},[_vm._v(" mdi-account-box-outline ")])],1),(item.phone)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.phone.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.phone.name))])]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.phone.phone))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.phone.phone))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 my-1",attrs:{"color":"primary","small":"","min-width":"0","width":"30","height":"30"},on:{"click":function($event){$event.stopPropagation();return _vm.bind(item)}}},on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('bind_contact')))])])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":"6"}},[_c('v-simple-table',{staticClass:"expanded-table",attrs:{"fixed-header":""}},[_c('tbody',[_vm._l((item.contact_book_phones),function(phones){return [(!phones.is_primary)?_c('tr',{key:phones.id,staticClass:"pa-0"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('name'))+": "),_c('span',{attrs:{"title":phones.name}},[_vm._v(" "+_vm._s(phones.name)+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t('phone'))+": "),_c('span',{attrs:{"title":phones.phone}},[_vm._v(" "+_vm._s(phones.phone)+" ")])])]):_vm._e()]})],2)])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }